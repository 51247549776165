import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Title from "./title";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {ChangePasswordContentHeader} from "../views/panel/worker/navigation/navigation";

function Modal (props) {
    return(
        <ModalWrapper onClick={props.onBack}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <ModalContentHeader>
                    <Title secondary>{props.title}</Title>
                    <div onClick={props.onBack}>
                        <FontAwesomeIcon icon={faTimesCircle}/>
                    </div>
                </ModalContentHeader>
                <div>
                    {props.children}
                </div>
            </ModalContent>
        </ModalWrapper>
    )
};

Modal.propTypes = {
};

export default Modal;

const ModalContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalContent = styled.div`
  width: 90%;
  max-width: 500px;
  padding: 20px;
  background: white;
  border-radius: 5px;
`;

const ModalWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: rgba(0,0,0,0.6);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
`;