import styled, {css} from "styled-components";

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faSortDown} from "@fortawesome/free-solid-svg-icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

function Button (props) {
    const [open,setOpen] = useState(null);
    return(
        <div style={{display:'flex',}}>
            <StyledButton {...props}>
                {props.children}
            </StyledButton>
            {
                props.subMenu &&
                <>
                    <StyledSubMenu aria-controls="simple-menu" onClick={(e) => setOpen(e.currentTarget)}>
                        <FontAwesomeIcon icon={faSortDown}/>
                    </StyledSubMenu>
                    <Menu
                        id="simple-menu"
                        anchorEl={open}
                        keepMounted
                        open={Boolean(open)}
                        onClose={() => setOpen(null)}
                    >
                        {
                            props.subMenu.map(item => <StyledMenuItem onClick={item[1]}>{item[0]}</StyledMenuItem>)
                        }
                    </Menu>
                </>
            }
        </div>
    )
};

Button.propTypes = {
};

export default Button;

const StyledMenuItem = styled(MenuItem)`
  font-size: ${({theme}) => theme.font.S};
  font-weight: ${({theme}) => theme.font.Regular};
`;

const StyledSubMenu = styled.button`
  padding: 0px 10px;
  border-left: 1px solid white;
  background: ${({theme,background}) => background || theme.primaryColor};
  border: none;
  display: inline-block;
  color: white;
  height: 100%;
  min-height: 38px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg{
    margin: 0px !important;
  }
    
  &.focus {
  outline: none;
}
`;

const StyledButton = styled.button`
  font-size: ${({theme}) => theme.font.S};
  color: white;
    cursor: pointer;
  font-weight: ${({theme}) => theme.font.Regular};
  background: ${({theme,background}) => background || theme.primaryColor};
  margin: ${({margin}) => margin || '0'};
   min-height: 38px;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  padding: 8px 25px;
  
  &.focus {
  outline: none;
}
  svg{
    margin-right: 10px;
    font-size: ${({theme}) => theme.font.XS};
  }
  
   ${({subMenu}) => subMenu && css`
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
  `}
   
   ${({secondary}) => secondary && css`
    background: ${({theme,background}) => background || theme.fourthColor};
    color: ${({theme}) => theme.secondColor};
    &:hover{
       color: ${({theme}) => theme.primaryColor};
    }
  `}
   
   ${({third}) => third && css`
    background: none;
    color: ${({theme}) => theme.secondColor};
     padding: 0px 25px;
     &:focus{
      outline: none;
     }
    &:hover{
       color: ${({theme}) => theme.primaryColor};
    }
  `}
   
  ${({big}) => big && css`
    width: 100%;
    line-height: ${({theme}) => theme.font.XL};
  `}
  
   ${({disabled}) => disabled && css`
    background: rgba(0,0,0,0.3) !important;
    cursor: not-allowed;
  `}
`;

