import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components'
import Title from "../../../../../../components/title";
import Table, { Row } from "../../../../../../components/table";
import axios from "axios";
import { connect } from "react-redux";

function MRProfiles({user}) {
  const [profiles, setProfiles] = useState([]);

  const loadUsers = async () => {
    try {
      const {data} = await axios.get('https://api.motorata.pl/api/profiles/all')
      setProfiles(data)
    } catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      <Wrapper>
        <ContentHeader>
          <Title>Wszystkie profile</Title>
        </ContentHeader>
        <ContentBody>
          <Table columns={['Nazwa', 'Slug', 'Typ', 'Miasto', 'Opiekun']}>
            {
              profiles.map(profile =>
                <Row data={[
                  profile.name,
                  profile.slug,
                  <StyledRole>
                    {profile.type}
                  </StyledRole>,
                  profile?.address?.city,
                  profile?.coordinator,
                ]}/>
              )
            }

          </Table>
        </ContentBody>
      </Wrapper>
    </>
  )
};

MRProfiles.propTypes = {};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

export default connect(mapStateToProps)(MRProfiles);


const StyledRole = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 15px;
  background: rgba(0, 0, 0, 0.1);
  width: max-content;
  margin: 0 auto;

  ${({role}) => role === 'pracownik' &&
          css`
            color: ${({theme}) => theme.primaryColor};
          `}

  ${({role}) => role === 'partner' &&
          css`
            color: ${({theme}) => theme.red};
          `}

  ${({role}) => role === 'dealer' &&
          css`
            color: ${({theme}) => theme.green};
          `}
`;

const ContentBody = styled.div`
  background: white;
  flex: 1 1;
  min-height: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Wrapper = styled.div`

`;
