import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import Title from "../../../../../../components/title";
import Button from "../../../../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import Table, {Row} from "../../../../../../components/table";
import {db} from "../../../../../../firebase/firebase";
import AddAccount from "./AddAccount";
import EditAccount from "./EditAccount";
import axios from "axios";
import {API_URL} from "../../../../../../theme/constans";
import {connect} from "react-redux";

function Users ({user}) {
    const [users,setUsers] = useState([]);
    const [addNew,setAddNew] = useState(false);
    const [editAccount,setEditAccount] = useState(null);

    const loadUsers = () => {
        let array = [];
        db.collection('users')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach(function (doc) {
                    array.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setUsers(array);
            })
    };

    useEffect(() => {
        loadUsers();
    },[]);

    const deleteuser = (user) => {
        var r = window.confirm("Czy chcesz usunąć użytkownika?");
        if (r === true) {
            const config = {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            };

            axios.get(`${API_URL}/deleteUser?id=${user.id}`,config)
                .then(() => {
                    window.confirm("Konto zostało usunięte");
                    loadUsers();
                })
        }
    };

    const translateRole = (user) => {
        if(user.role === 'worker' && user.options && user.options.includes('partner')){
            return 'partner'
        }else if(user.role === 'worker'){
            return 'pracownik'
        }
        return 'dealer'
    };

    return(
        <>
            <Wrapper>
                <ContentHeader>
                    <Title>Użytkownicy</Title>
                    <Button onClick={() => setAddNew(true)}>Dodaj konto</Button>
                </ContentHeader>
                <ContentBody>
                    <Table columns={['Nazwa','Email','Telefon','Rola', null, null]}>
                        {
                            users.sort(function (a, b) {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (b.name < a.name) {
                                    return 1;
                                }
                                return 0;
                            }).map(user =>
                                <Row data={[
                                    user.name ? user.name : '-',
                                    user.email,
                                    user.phone ? user.phone : '-',
                                    <StyledRole role={translateRole(user)}>
                                        {translateRole(user)}{user.commission && user.commission !== 0 && user.commission !== '0' ? ` +${user.commission}%` : ''}
                                    </StyledRole>,
                                    <FontAwesomeIcon icon={faPen} onClick={() => setEditAccount(user)}/>,
                                    <FontAwesomeIcon icon={faTrash} onClick={() => deleteuser(user)}/>
                                ]}/>
                            )
                        }

                    </Table>
                </ContentBody>
            </Wrapper>
            {
                addNew &&
                    <AddAccount refresh={loadUsers} onBack={() => setAddNew(false)}/>
            }
            {
                editAccount &&
                <EditAccount account={editAccount} refresh={loadUsers} onBack={() => setEditAccount(null)}/>
            }
        </>
    )
};

Users.propTypes = {
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
export default connect(mapStateToProps)(Users);


const StyledRole = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 15px;
  background: rgba(0,0,0,0.1);
  width: max-content;
  margin: 0 auto;
  
  ${({role}) => role === 'pracownik' && 
    css`
    color: ${({theme}) => theme.primaryColor};
  `}
    
 ${({role}) => role === 'partner' &&
    css`
    color: ${({theme}) => theme.red};
  `}
     
  ${({role}) => role === 'dealer' &&
    css`
    color: ${({theme}) => theme.green};
  `}
`;

const ContentBody = styled.div`
  background: white;
  flex: 1 1;
  min-height: 100%;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


const Wrapper = styled.div`

`;
