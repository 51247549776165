import {db, myFirebase} from "../../firebase/firebase";

export const LOAD_OFFERS = "LOAD_OFFERS";

const loadOffers = (offers) => {
    return {
        type: LOAD_OFFERS,
        payload:offers
    };
};

export const loadOffersFunc = () => dispatch => {
    myFirebase
        .auth()
        .onAuthStateChanged(user => {
            if (user !== null) {
                db.collection('offers')
                    .where("dealerId","==",user.uid)
                    .get()
                    .then((res) => {
                        let array = [];
                        res.forEach(function(doc) {
                            array.push(doc.data());
                        });

                        dispatch(loadOffers(array));
                    });
            }
        });
};
