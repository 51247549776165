import React from "react";
import styled from "styled-components"
import Title from "../../../../../../components/title";
import Button from "../../../../../../components/button";
import Spinner from "../../../../../../components/spinner";
import OlxTests from "./components/OlxTests";

const Tests = () => {
  return (
    <Wrapper>
      <Header>
        <Title>Testowanie</Title>
      </Header>
      <Content>
        <OlxTests />
      </Content>
    </Wrapper>
  )
}

export default Tests

const Wrapper = styled.div`

`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
  grid-gap: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
