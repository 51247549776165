import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import LOGO from '../../../../images/logo.svg'
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
    faBuilding,
    faCar, faCog,
    faColumns,
    faFileContract,
    faFileImage,
    faHandshake,
    faHeadset, faLock, faPlug,
    faPlus,
    faSignOutAlt, faTimesCircle, faUser,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {logoutUser} from "../../../../redux/actions";
import Title from "../../../../components/title";
import {P} from "../../../../components/p";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import {myFirebase} from "../../../../firebase/firebase";
import {StyledErrorMessage} from "../../../../theme/styledComponents";
import Spinner from "../../../../components/spinner";
import {version} from "../../../../App";
const firebase = require('firebase');

function Navigation (props) {
    const {dispatch,user} = props;
    const [changePassword,setChangePassword] = useState(false);
    const [changePasswordLoading,setChangePasswordLoading] = useState(false);
    const [changePasswordError,setChangePasswordError] = useState(false);
    const [state,setState] = useState({
        oldpassword:'',
        newpassword:'',
    });

    const updatePassword = () => {
        setChangePasswordLoading(true);
       const user = myFirebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, state.oldpassword);

        user.reauthenticateWithCredential(cred)
            .then(() =>{
                user.updatePassword(state.newpassword)
                    .then(response => {
                        setState({
                            oldpassword:'',
                            newpassword:'',
                        });
                        setChangePassword(false);
                        setChangePasswordLoading(false);
                    })
            })
            .catch(err => {
                setChangePasswordError(true);
                setChangePasswordLoading(false);
            })
    };

    return(
        <>
            <Wrapper>
                <div>
                    <Logo><img src={LOGO}/></Logo>
                    <NavLinksWrapper>
                        {/*<Label>MotoLead</Label>*/}
                        {/*<StyledNavLink exact to="/panel/motolead/dashboard">*/}
                        {/*    <FontAwesomeIcon icon={faColumns}/> Panel*/}
                        {/*</StyledNavLink>*/}
                        <Label>Dodatkowe narzędzia</Label>
                        <StyledNavLink exact to="/panel/generator">
                            <FontAwesomeIcon icon={faFileImage}/> Generator oferty
                        </StyledNavLink>
                        <StyledNavLink exact to="/panel/offers">
                            <FontAwesomeIcon icon={faCar}/> Wszystkie oferty
                        </StyledNavLink>
                        <Label>MotoRata</Label>
                        <StyledNavLink exact to="/panel/motorata/profiles">
                            <FontAwesomeIcon icon={faBuilding}/> Lista profili
                        </StyledNavLink>
                        {
                            user.authorization && user.authorization.includes('rent') &&
                                <>
                                    <Label>MyRent</Label>
                                    <StyledNavLink exact to="/panel/my-rent">
                                        <FontAwesomeIcon icon={faFileContract}/> Generator umowy
                                    </StyledNavLink>
                                </>
                        }
                        {
                            user.authorization && (user.authorization.includes('users') || user.authorization.includes('general')) &&
                                <>
                                    <Label>Administracja</Label>
                                    {
                                        user.authorization.includes('general') &&
                                        <StyledNavLink exact to="/panel/settings/general">
                                            <FontAwesomeIcon icon={faCog}/> Ogólne ustawienia
                                        </StyledNavLink>
                                    }
                                    {
                                      user.authorization.includes('general') &&
                                      <StyledNavLink exact to="/panel/settings/tests">
                                          <FontAwesomeIcon icon={faPlug}/> Testowanie
                                      </StyledNavLink>
                                    }
                                    {
                                        user.authorization.includes('users') &&
                                        <StyledNavLink exact to="/panel/settings/users">
                                            <FontAwesomeIcon icon={faUsers}/> Użytkownicy
                                        </StyledNavLink>
                                    }
                                </>
                        }
                    </NavLinksWrapper>
                </div>
                <div>
                    <StyledButton  onClick={() => setChangePassword(true)}>
                        <FontAwesomeIcon icon={faLock}/> Zmień hasło
                    </StyledButton>
                    <StyledButton  onClick={() => dispatch(logoutUser())}>
                        <FontAwesomeIcon icon={faSignOutAlt}/> Wyloguj się
                    </StyledButton>
                    <Version>
                        v{version}
                    </Version>
                </div>
                {/*<StyledButton onClick={() => dispatch(logoutUser())}>*/}
                {/*    <FontAwesomeIcon icon={faSignOutAlt}/> Wyloguj się*/}
                {/*</StyledButton>*/}
            </Wrapper>
            {
                changePassword &&
                <ChangePasswordWrapper onClick={() => setChangePassword(false)}>
                    <ChangePasswordContent onClick={(e) => e.stopPropagation()}>
                        <ChangePasswordContentHeader>
                            <Title secondary> Zmiana hasła</Title>
                            <div onClick={() => setChangePassword(false)}>
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </div>
                        </ChangePasswordContentHeader>
                        <ChangePasswordContentBody>
                            <P>Zmień dotychczasowe hasło do panelu. Nowe hasło musi składać się minimum z 6 znaków.</P>
                            <Input label="Stare hasło" password value={state.oldpassword} onChange={(e) => setState({...state,oldpassword:e.target.value})}/>
                            <Input label="Nowe hasło" password value={state.newpassword} onChange={(e) => setState({...state,newpassword:e.target.value})}/>
                            {
                                changePasswordError &&
                                <StyledErrorMessage margin="15px 0 5px 0" textAlign="center">
                                    Nie udało się zmienić hasła. Sprawdź czy stare hasło jest prawidłowe.
                                </StyledErrorMessage>
                            }
                            <Button
                                big
                                margin="20px 0 0 0"
                                disabled={state.newpassword.length < 1 || state.oldpassword.length < 1 }
                                onClick={updatePassword}
                            >
                                {
                                    changePasswordLoading ?
                                        <Spinner borderWidth={3} white width={10} height={10}/> :
                                        <><FontAwesomeIcon icon={faLock}/> Zmień hasło</>
                                }
                            </Button>
                        </ChangePasswordContentBody>
                    </ChangePasswordContent>
                </ChangePasswordWrapper>
            }
        </>
    )
};

Navigation.propTypes = {
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
        isLoggingIn: state.auth.isLoggingIn,
        loginError: state.auth.loginError,
        isAuthenticated: state.auth.isAuthenticated
    };
}
export default connect(mapStateToProps)(Navigation);

export const Version = styled.p`
  text-align: center;
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  color: ${({theme}) => theme.thirdColor};
`;

export const ChangePasswordContentBody = styled.div`

`;

export const ChangePasswordContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChangePasswordContent = styled.div`
  width: 90%;
  max-width: 500px;
  padding: 20px;
  background: white;
  border-radius: 5px;
`;

const ChangePasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: rgba(0,0,0,0.6);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const Label = styled.label`
  width: 100%;
  background: rgba(0,0,0,0);
  padding: 3px 20px;
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.XS};
  margin-bottom: 5px;
  margin-top: 25px;
  display: block;
  text-transform: uppercase;
  font-weight: ${({theme}) => theme.font.Bold};
`;

const StyledButton = styled.button`
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 8px;
  padding-left: 20px;
  border-radius: 5px;
  // background: ${({theme}) => theme.primaryBackground};
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.M};
  font-weight: ${({theme}) => theme.font.Regular};
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  
  svg{
    min-width: 25px;
    margin-right: 10px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 8px;
  padding-left: 20px;
  border-radius: 5px;
   //background: rgba(0,0,0,0.03);
  color: ${({theme}) => theme.thirdColor};
  font-size: ${({theme}) => theme.font.M};
  font-weight: ${({theme}) => theme.font.Regular};
  position: relative;
  margin-bottom: 10px;
  transition: all 0.3s;
  cursor: pointer;
  
  svg{
    min-width: 25px;
    margin-right: 10px;
  }
  a{
    font-size: 13px;
    //background: ${({theme}) => theme.primaryColor};
    color: white;
    display: block;
    height: 22px;
    width: 25px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    
    svg{
    width: 13px;
    height: 13px;
    margin: 0px;
    }
  }
  &:hover{
     color:${({theme}) => theme.secondColor};
  }
  
  &.active{
    background: ${({theme}) => theme.primaryBackground};
    color:${({theme}) => theme.primaryColor};
  }
  
`;

const NavLinksWrapper = styled.div`

`;

const Logo = styled.div`
  margin-top: 40px;
  padding: 0 20px;
  margin-bottom: 40px;
  img{
    max-height: 70px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Wrapper = styled.div`
  background: white;
  height: 100%;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 20px;
`;
