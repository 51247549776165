export const theme = {
  primaryColor:'rgb(54,118,181)',
  secondColor:'rgb(39,39,39)',
  thirdColor:'rgb(100,100,100)',
  fourthColor:'rgb(235,235,235)',
  primaryBackground:'rgba(13, 119, 187,0.1)',

  green:'rgb(85,163,66)',
  greenBackground:'rgba(224,239,222,0.7)',
  blue:'rgb(77,177,249)',
  blueBackground:'rgba(214,237,251,0.6)',
  yellow:'rgb(245,180,64)',
  yellowBackground:'rgb(253,242,217)',
  red:'#cc0000',

  font:{
    XXS:'12px',
    XS:'14px',
    S:'16px',
    M:'18px',
    L:'20px',
    XL:'22px',
    XLL:'26px',
    XXL:'30px',

    Bold:'700',
    Regular:'600',
    Light:'500'
  },
};

export const monthsOptions = [24,30,35,36,47,48,59,60,72,84];

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export function parseNumber(x) {
  if(x){
    return parseFloat(x.replace(',','.')).toFixed(2).toString().replace('.',',')
  }
  return ''
}

function generatePriceString(number)
{
  var liczba = parseInt(number);

  var jednosci = ["", " jeden", " dwa", " trzy", " cztery", " pięć", " sześć", " siedem", " osiem", " dziewięć"];
  var nascie = ["", " jedenaście", " dwanaście", " trzynaście", " czternaście", " piętnaście", " szesnaście", " siedemnaście", " osiemnaście", " dziewietnaście"];
  var dziesiatki = ["", " dziesięć", " dwadzieścia", " trzydzieści", " czterdzieści", " pięćdziesiąt", " sześćdziesiąt", " siedemdziesiąt", " osiemdziesiąt", " dziewięćdziesiąt"];
  var setki = ["", " sto", " dwieście", " trzysta", " czterysta", " pięćset", " sześćset", " siedemset", " osiemset", " dziewięćset"];
  var grupy = [
    ["" ,"" ,""],
    [" tysiąc" ," tysiące" ," tysięcy"],
    [" milion" ," miliony" ," milionów"],
    [" miliard"," miliardy"," miliardów"],
    [" bilion" ," biliony" ," bilionów"],
    [" biliard"," biliardy"," biliardów"],
    [" trylion"," tryliony"," trylionów"]];

  // jezeli pole zawiera poprawna wartosc calkowita
  if (liczba == liczba.toString()){

    var wynik = '';
    var znak = '';
    if (liczba == 0)
      wynik = "zero";
    if (liczba < 0) {
      znak = "minus";
      liczba = -liczba;
    }

    var g = 0;
    while (liczba > 0) {
      var s = Math.floor((liczba % 1000)/100);
      var n = 0;
      var d = Math.floor((liczba % 100)/10);
      var j = Math.floor(liczba % 10);

      if (d == 1 && j>0) {
        n = j;
        d = 0;
        j = 0;
      }

      var k = 2;
      if (j == 1 && s+d+n == 0)
        k = 0;
      if (j == 2 || j == 3 || j == 4)
        k = 1;
      if (s+d+n+j > 0)
        wynik = setki[s]+dziesiatki[d]+nascie[n]+jednosci[j]+grupy[g][k]+wynik;

      g++;
      liczba = Math.floor(liczba/1000);
    }
    return wynik
  }

  return '';
}

export const changePriceToString = (price) => {
  if(price){
    const liczba = parseNumber(price).split(',')
    return `${generatePriceString(liczba[0])} złotych i ${generatePriceString(liczba[1])} groszy`
  }
  return ''
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
