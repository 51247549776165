import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAXEtfOrAyZWiHVYaH0nZ0bk7P8QxnVK64",
    authDomain: "mylease-pl.firebaseapp.com",
    databaseURL: "https://mylease-pl.firebaseio.com",
    projectId: "mylease-pl",
    storageBucket: "mylease-pl.appspot.com",
    messagingSenderId: "832004286626",
    appId: "1:832004286626:web:46030d71a25eb937408ffb",
    measurementId: "G-0HLXPBS128"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;