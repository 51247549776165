import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import {Select as MUSelect} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import styled from "styled-components";

function Select ({label,options,onChange,value,name,maxWidth, marginTop = "15px"}) {
    return(
        <Wrapper maxWidth={maxWidth} marginTop={marginTop}>
            <FormControl variant="outlined">
                <InputLabel htmlFor="age-native-required">{label}</InputLabel>
                <MUSelect
                    native
                    value={value}
                    onChange={onChange}
                    label={label}
                    inputProps={{
                        name: name,
                        id: 'outlined-age-native-simple',
                    }}
                >
                    {/*<option aria-label="None" value="" />*/}
                    {
                        options.map(item =>  <option value={item}>{item}</option>)
                    }
                </MUSelect>
            </FormControl>
        </Wrapper>
    )
};

Select.propTypes = {
};

const Wrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop};
  min-width: ${({maxWidth}) => maxWidth || '200px'};
  
  .MuiFormControl-root{
    width: 100%;
  }
 .MuiOutlinedInput-input{
    padding: 12px 18px;
  }
    .MuiInputLabel-outlined{
    transform: translate(14px, 15px) scale(1);
  }
`;

export default Select;