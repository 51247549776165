import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import ProtectedRoute from "../components/protectedRoute";
import Login from "./login/login";
import Panel from "./panel/panel";
import LOGO from '../images/logo.svg'
import Pdf from "./pdf/pdf";
import MSMPdf from "./pdf/msm-pdf";
import RentPdf from "./pdf/rent";
import { loadConfigurationWorker } from "../redux/actions";

function Home (props) {
    const dispatch = useDispatch()
    const { isAuthenticated, isVerifying } = props;

    useEffect(() => {
        dispatch(loadConfigurationWorker())
    })


    return(
        <>
            <BrowserRouter>
                <Switch>
                    <ProtectedRoute
                        path="/panel"
                        component={Panel}
                        isAuthenticated={isAuthenticated}
                        isVerifying={isVerifying}
                    />
                    <Route path="/login" component={Login} />
                    <Route path="/pdf" component={Pdf} />
                    <Route path="/msm-pdf" component={MSMPdf} />
                    <Route path="/rent" component={RentPdf} />

                    <Route render={() => <Redirect to="/login" />} />
                </Switch>
            </BrowserRouter>
            <Mobile>
                <div>
                    <img src={LOGO}/>
                    <p>Proszę otworzyć na urządzeniu o wyższej rozdzielczości.</p>
                </div>
            </Mobile>
        </>

    )
};

Home.propTypes = {
};

function mapStateToProps(state) {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        isVerifying: state.auth.isVerifying
    };
}
export default connect(mapStateToProps)(Home);

const Mobile = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  >div{
    width: 80%;
    max-width: 700px;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 80px;
    }
    p{
      font-size: 20px;
      text-align: center;
      color: ${({theme}) => theme.primaryColor};
      font-weight: 600;
    }
  }
  
  @media (max-width: 1000px){
    display: flex;
  }
`;
