import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Title from "../../../../../../components/title";
import Button from "../../../../../../components/button";
import Block from "../../../../../../components/Block";
import Input from "../../../../../../components/input";
import { db } from "../../../../../../firebase/firebase";
import Spinner from "../../../../../../components/spinner";
import { useDispatch, useSelector } from "react-redux";
import { setConfigurationProperty } from "../../../../../../redux/actions";
import MarginsBlock from "./components/MarginsBlock";
import CommissionBlock from "./components/CommissionBlock";
import DeploySite from "./components/DeploySite";
import {FINANCE_TYPES, YEARS} from "../../../../../../theme/constans";
import update from 'immutability-helper';

const General = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const configuration = useSelector(store => store.configuration)
  const [state, setState] = useState({
    wibor: configuration.wibor,
    olxCommission: configuration.olxCommission,
    margin: configuration.margin,
    commission: configuration.commission,
  })

  const parseMargins = (margins) => {
    let tmp = margins;
    FINANCE_TYPES.forEach(ft =>
      YEARS.forEach(year =>
        tmp = update(tmp, {
          [ft[1]]: {
            [year]: {
              $set: tmp[ft[1]][year] ? parseFloat(tmp[ft[1]][year]) : 0.00
            }
          }
        })
      )
    )

    return tmp
  }

  const handleSave = useCallback(() => {
    setLoading(true)
    db.collection('configuration')
    .doc("general")
    .set({
      ...state,
      wibor: parseFloat(state.wibor),
      olxCommission: parseFloat(state.olxCommission),
      margin: parseMargins(state.margin),
    })
    .finally(() => {
      setLoading(false)
      dispatch(setConfigurationProperty({
        wibor: parseFloat(state.wibor),
        olxCommission: parseFloat(state.olxCommission),
        margin: parseMargins(state.margin),
      }))
    })
  }, [state])

  const handleOnChange = useCallback((e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }, [state])

  return (
    <Wrapper>
      <Header>
        <Title>Ogólne ustawienia</Title>
        <Button onClick={handleSave}>
          {
            loading ?
              <Spinner borderWidth={3} white width={10} height={10}/> :
              <>Zapisz</>
          }
        </Button>
      </Header>
      <Content>
        <Block title="WIBOR">
          <Input type="double" marginTop="0" label="" name="wibor" value={state.wibor} onChange={handleOnChange}/>
        </Block>
        <Block title="Prowizja OLX">
          <Input type="double" marginTop="0" label="" name="olxCommission" value={state.olxCommission} onChange={handleOnChange}/>
        </Block>
        <MarginsBlock state={state} setState={setState} />
        <CommissionBlock state={state} setState={setState} />
        <DeploySite />
      </Content>
    </Wrapper>
  );
}

export default General;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  grid-gap: 20px;
`;

const Wrapper = styled.div`

`;


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
