import styled, { css } from 'styled-components'

const Title = styled.h2`
  margin: ${({margin}) => margin || '0px'};
  font-size: ${({theme}) => theme.font.XLL};
  color: ${({theme}) => theme.secondColor};
  font-weight: 800;
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px !important;
  }

  ${({secondary}) => secondary && css`
    font-size: ${({theme}) => theme.font.L};
  `}
  ${({third}) => third && css`
    font-size: ${({theme}) => theme.font.M};
    opacity: 1;
    color: ${({theme}) => theme.thirdColor};
    font-weight: 500;
  `}

  ${({fourth}) => fourth && css`
    font-size: ${({theme}) => theme.font.XS};
    opacity: 1;
    color: ${({theme}) => theme.thirdColor};
    font-weight: 500;
    margin-bottom: 0px;
  `}

`;

export default Title;
