import React from 'react';
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";
import Students from "./partner/content/otomoto/otomoto";
import {connect} from "react-redux";
import Partner from "./partner/partner";
import Worker from "./worker/worker";

function Panel (props) {
    const {user} = props;

    let component;

    if(user.role === 'dealer'){
        component = Partner;
    }
    if(user.role === 'worker'){
        component = Worker;
    }

    return(
        <>
            <Route path = '/panel' component={component} />
        </>
    )
};

Panel.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Panel);