import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen, faUsers} from "@fortawesome/free-solid-svg-icons";

export function Row ({data}) {
    return(
        <tr>
            {
                data.map(column =>
                    <td>{column}</td>
                )
            }
        </tr>
    )
};

function Table ({columns,children}) {
    return(
        <TableWrapper>
            <ContentTable cellspacing="0" cellpadding="0">
                <tr>
                    {
                        columns.map(column =>
                            <th>{column}</th>
                        )
                    }
                </tr>
                {children}
            </ContentTable>
        </TableWrapper>
    )
};

Table.propTypes = {
};

export default Table;

const TableWrapper = styled.div`
  //flex: 1 1;
`;

const ContentTable = styled.table`
 border-collapse:collapse;
  width: 100%;

  tr{
    border-bottom: 1px solid ${({theme}) => theme.fourthColor};
    &:first-of-type, &:last-of-type{
       border-bottom: 0px;
    }
  }
  th,td{
    border: none;
    padding: 0px;
  }
  th{
    background: ${({theme}) => theme.fourthColor};
    font-size: ${({theme}) => theme.font.XS};
    font-weight: ${({theme}) => theme.font.Regular};
    color: ${({theme}) => theme.secondColor};
    padding: 6px 8px;
    text-align: left;
    &:first-of-type{
      border-radius: 3px 0 0 3px;
    }
     &:last-of-type{
      border-radius: 3px 0 0 3px;
    }
    &.textAlign-center{
      text-align: center;
    }
  }
  td{
    padding: 8px 8px;
    font-size: ${({theme}) => theme.font.XS};
    color: ${({theme}) => theme.thirdColor};
     font-weight: ${({theme}) => theme.font.Light};
     svg{
      margin: 0 auto;
      display: block;
     }
     &.name{
         color: ${({theme}) => theme.secondColor};
          min-width: 200px;
     }
     &.icons{
     display: flex;
        justify-content: flex-end;
        padding-right: 10px;
     svg{
      cursor: pointer;
      margin: 0;
      &:first-of-type{
        margin-right: 10px;
      }
     }
     
     }
     span{
      text-transform: uppercase;
         font-size: ${({theme}) => theme.font.XS};
         border-radius: 3px;
        padding: 3px 15px;
        font-weight: ${({theme}) => theme.font.Bold};
     }
    &.web{
      span{
        background:  ${({theme}) => theme.greenBackground};
        color: ${({theme}) => theme.green};
      }
    }
    
    &.comperia{
      span{
        background: ${({theme}) => theme.blueBackground};
        color: ${({theme}) => theme.blue};
      }
    }
    
    &.wlasny{
      span{
        background: ${({theme}) => theme.yellowBackground};
        color: ${({theme}) => theme.yellow};
      }
    }
    
    &.patron{
      span{
        height: 35px;
        width: 35px;
        margin: 0 auto;
        display: block;
        background: ${({theme}) => theme.fourthColor};
         font-weight: ${({theme}) => theme.font.Bold};
         font-size: ${({theme}) => theme.font.XS};
         display: flex;
         align-items: center;
         justify-content: center;
      }
    }
  }
`;