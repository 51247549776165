import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import InputAdornment from "@material-ui/core/InputAdornment";


const INT_REGEX = /^[0-9]*$/
const DOUBLE_REGEX = /^[0-9]*\.?[0-9]*$/

function Input (props) {

    const handleOnChange = useCallback((e) => {
        switch (props.type){
            case "int":
                if(INT_REGEX.test(e.target.value)){
                    props.onChange(e)
                }
                break;
            case "double":
                if(DOUBLE_REGEX.test(e.target.value)){
                    props.onChange(e)
                }
                break;
            default:
                props.onChange(e)
        }
    }, [props.type,props.onChange])

    return(
        <Wrapper marginTop={props.marginTop} maxWidth={props.maxWidth}>
            <TextField
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                name={props.name}
                type={props.password ? "password" : "text"}
                InputProps={props.endAdornment && {
                    endAdornment: <InputAdornment position="start">{props.endAdornment}</InputAdornment>,
                }}
                {...props}
                onChange={handleOnChange}
            />
            <StyledP>{props.info}</StyledP>
        </Wrapper>
    )
};

Input.propTypes = {
};

export default Input;

const StyledP = styled.p`
  font-size: 12px;
  margin: 0px;
  margin-top: 5px;
  `;

const Wrapper = styled.div`
  max-width: ${({maxWidth}) => maxWidth || 'none'};
  margin-top: ${({marginTop}) => marginTop || '15px'};
  
 .MuiFormControl-root{
   width: 100%;
 }
  .MuiOutlinedInput-input{
    padding: 12px 18px;
   
  }
  .MuiInputLabel-outlined{
    transform: translate(14px, 15px) scale(1);
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:${({theme}) => theme.primaryColor};
  }
    
`;
