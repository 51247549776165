import React, {useEffect, useState} from 'react';
import Spinner from "../../../../../components/spinner";
import axios from "axios";
import Table, {Row} from "../../../../../components/table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip} from "@fortawesome/free-solid-svg-icons";
import Input from "../../../../../components/input";
import styled from 'styled-components'

function WebsiteOffers() {
    const [offers, setOffers] = useState();
    const [search, setSearch] = useState('');

    useEffect(() => {
        axios.get('https://api.allorigins.win/get?url=https://admin.myfleet.force4.pl/wp-json/rest/v1/offers/')
            .then((res) => setOffers(JSON.parse(res.data.contents)))
    }, []);

    const translator = {
        'bmw': 'BMW',
        'mercedes-benz': 'Mercedes Benz',
        'kia': 'Kia',
        'volvo': 'Volvo',
        'seat': 'Seat',
        'audi': 'Audi',
        'peugeot': 'Peugeot',
        'mazda': 'Mazda',
        'hyundai': 'Hyundai',
        'volkswagen': 'Volkswagen',
        'ford': 'Ford',
        'toyota': 'Toyota',
        'renault': 'Renault',
        'opel': 'Opel',
        'nissan': 'Nissan',
        'dacia': 'Dacia',
        'skoda': 'Skoda',
        'mini': 'Mini',
        'alfa-romeo': 'Alfa Romeo',
    };

    return (
        <>
            {
                !offers ?
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Spinner/>
                    </div>
                    :
                    <Wrapper>
                        <Input label="Szukaj" value={search} onChange={(e) => setSearch(e.target.value)}
                               maxWidth="500px"/>

                        <Table columns={['Marka', 'Model', 'Wersja', 'Silnik', 'Cena', 'Prowizja', 'Marza', null]}>
                            {
                                offers.map(offer =>
                                    offer.configurations.map(({config}) =>
                                        `${translator[offer.brand]} ${offer.model} ${config.version}`.toUpperCase().includes(search.toUpperCase()) ?
                                            <Row data={[
                                                translator[offer.brand],
                                                offer.model,
                                                config.version,
                                                `${config.engineCapacity}l / ${config.power} KM`,
                                                `${config.price} PLN`,
                                                `${config.leasing?.prowizja}%`,
                                                `${config.leasing?.marza_banku}%`,
                                                config.specification && <a target="_blank" href={config.specification}>
                                                    <FontAwesomeIcon icon={faPaperclip}/>
                                                </a>
                                            ]}/> : null
                                    )
                                )
                            }
                        </Table>
                    </Wrapper>
            }
        </>
    )
}

;

WebsiteOffers.propTypes =
{
}
;

export default WebsiteOffers;

const Wrapper = styled.div`
  background: white;
  border-radius: 3px;
  padding: 10px;

  > div:first-of-type {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
`;