import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Modal from "../../../../../components/modal";
import {P} from "../../../../../components/p";
import Input from "../../../../../components/input";
import {StyledErrorMessage} from "../../../../../theme/styledComponents";
import Button from "../../../../../components/button";
import Spinner from "../../../../../components/spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {ChangePasswordContentBody} from "../../navigation/navigation";
import {db} from "../../../../../firebase/firebase";
import {connect} from "react-redux";
import {makeid} from "../../../../../theme/cookies";

function SaveNew ({onSuccess,state,leasing,loan,rent,onBack,user}) {
    const [loading,setLoading] = useState(false);
    const [name,setName] = useState('');

    const onSave = () => {
        setLoading(true);
        const id = makeid(20);
        const object = {
            state:{
                ...state,
                id:id
            },
            leasing,
            loan,
            rent
        };

        console.log(object);

        db.collection('users').doc(user.uid).collection('templates').doc(id).set({
            id: id,
            name: name,
            object: JSON.stringify(object)
        })
            .then(response => {
                onSuccess(id,name,object);
                setLoading(false);
                onBack();
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            })
    };

    return(
        <>
            <Modal title="Dodaj nowy szablon" onBack={onBack}>
                <P>Podaj nazwę Twojego nowego szablonu.</P>
                <Input label="Nazwa" value={name} onChange={(e) => setName(e.target.value)}/>
                <Button
                    big
                    margin="20px 0 0 0"
                    // disabled={state.newpassword.length < 1 || state.oldpassword.length < 1 }
                    onClick={onSave}
                >
                    {
                        loading ?
                            <Spinner borderWidth={3} white width={10} height={10}/> :
                            <>Dodaj szablon</>
                    }
                </Button>
            </Modal>
        </>
    )
};

SaveNew.propTypes = {
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
export default connect(mapStateToProps)(SaveNew);
