import {LOAD_OFFERS} from "../actions/offers";


export default (
    state = [
        {
            status: 'active'
        },
        {
            status: 'active'
        },
        {
            status: 'pending'
        },
    ],
    action
) => {
    switch (action.type) {
        case LOAD_OFFERS:
            return action.payload;
        default:
            return state;
    }
};