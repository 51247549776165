import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components'
import Modal from "../../../../../../components/modal";
import {P} from "../../../../../../components/p";
import Input from "../../../../../../components/input";
import Button from "../../../../../../components/button";
import Spinner from "../../../../../../components/spinner";
import {adminFirebase, db, myFirebase} from "../../../../../../firebase/firebase";
import {connect} from "react-redux";
import axios from "axios";
import {API_URL} from "../../../../../../theme/constans";
import {StyledErrorMessage} from "../../../../../../theme/styledComponents";


function EditAccount ({user,account,onBack,refresh}) {
    const [loading,setLoading] = useState(false);
    const [error,setError] = useState('');
    const [state,setState] = useState({
        role:account.options && account.options.includes('partner') ? 'partner' : account.role,
        name:account.name ? account.name : '',
        phone:account.phone ? account.phone : '',
        commission: account.commission ? account.commission : '0',
    });

    const onSave = () => {
        setLoading(true);

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        };

        axios.get(`${API_URL}/updateUser?id=${account.id}&name=${state.name}&phone=${state.phone}&commission=${state.role === 'partner' ? state.commission : 0}`,config)
            .then(response => {
                setLoading(false);
                refresh();
                onBack();
            }).catch(err => {
                setLoading(false);
                setError('Nie udało sie edytowć konta.');
        })
    };

    const handleDoubleInputChange = (e) => {
        if (e.target.value === "" || /^[0-9]+\.?[0-9]*$/.test(e.target.value)) {
            setState({...state,[e.target.name]:e.target.value});
        }
    };

    return(
        <>
            <Modal title="Edytuj konto" onBack={onBack}>
                <P>Podaj nowe dane użytkownika.</P>

                <Input label="Nazwa" value={state.name} onChange={(e) => setState({...state,name:e.target.value})}/>
                <Input label="Telefon" value={state.phone} onChange={(e) => setState({...state,phone:e.target.value})}/>
                {state.role === 'partner' && <Input label="Dodatkowa prowizja" name="commission" value={state.commission} onChange={handleDoubleInputChange}/>}
                {error !== '' && <StyledErrorMessage textAlign="center" margin="20px 0 0 0">{error}</StyledErrorMessage>}
                <Button
                    big
                    margin="20px 0 0 0"
                    disabled={state.name.length < 1 || state.phone.length < 1 || state.commission === ""}
                    onClick={onSave}
                >
                    {
                        loading ?
                            <Spinner borderWidth={3} white width={10} height={10}/> :
                            <>Edytuj konto</>
                    }
                </Button>
            </Modal>
        </>
    )
};

EditAccount.propTypes = {
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
export default connect(mapStateToProps)(EditAccount);

const RadioButton = styled.div`
   background: none;
   color: ${({theme}) => theme.secondColor};
   padding: 3px 5px;
   text-align: center;
   border-radius: 3px;
   font-weight: 500;
   cursor: pointer;
   transition: all 0.3s;
   
   &:hover{
    color: ${({theme}) => theme.primaryColor};
   }
   ${({active}) => active && 
    css`
      background: ${({theme}) => theme.primaryColor};
      color: white !important;
  `}
   
 
`;

const WrapperRadio = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 3px;
  border-radius: 3px;
  background: ${({theme}) => theme.fourthColor};
`;
