export const getPricesInString = (priceParam,owValueParam,owTypeParam,rvValueParam,rvTypeParam) => {
    let price = parseFloat(priceParam.replaceAll(',','.'));
    let owValue = parseFloat(owValueParam.replaceAll(',','.'));
    let rvValue = parseFloat(rvValueParam.replaceAll(',','.'));

    let wplata = [];
    let wykup = [];

    if(owTypeParam === "%"){
        wplata[0] = Math.round((price*(owValue/100))).toString().replaceAll('.',',');
        wplata[1] = owValueParam;
        wplata[2] = owValue;
    }else {
        wplata[0] = owValueParam;
        wplata[1] = Math.round((owValue/price)*100).toString().replaceAll('.',',');
        wplata[2] = Math.round((owValue/price)*100);
    }

    if(rvTypeParam === "%"){
        wykup[0] = Math.round((price*(rvValue/100))).toString().replaceAll('.',',');
        wykup[1] = rvValueParam;
        wykup[2] = rvValue;
    }else {
        wykup[0] = rvValueParam;
        wykup[1] = Math.round((rvValue/price)*100).toString().replaceAll('.',',');
        wykup[2] = Math.round((rvValue/price)*100);
    }

    return [wplata,wykup]
};