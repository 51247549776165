import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Title from "../../../../../../components/title";

function Panel () {
    return(
        <Wrapper>
            <Title>Panel</Title>
        </Wrapper>
    )
};

Panel.propTypes = {
};

export default Panel;

const Wrapper = styled.div`

`;
