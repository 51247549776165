import React from 'react';
import styled from 'styled-components';
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";
import {Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer';
import {getPricesInString} from "../panel/worker/content/offerGenerator/functions";
import LOGO from "../../images/logo.png";
import {changePriceToString, numberWithSpaces, parseNumber} from "../../theme/theme";
import OFFER_IMAGE from "../../images/offerImage.png";
import SRWIS from "../../images/car-repair.png";
import TIRES from "../../images/wheel.png";
import REPLACE_CAR from "../../images/replace-car.png";
import INSURANCE from "../../images/insurance.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import O1 from "../../images/partner/1.png";
import O2 from "../../images/partner/2.png";
import O3 from "../../images/partner/3.png";
import MONTSERRAT_LIGHT from "../../assets/montserrat/Montserrat-SemiBold.ttf";
import VERDANA_NORMAL from "../../assets/verdana/verdana.ttf";
import VERDANA_BOLD from "../../assets/verdana/Verdana-bold.ttf";
import moment from 'moment'
import 'moment/locale/pl'

const ParagraphHeading = ({number,text}) => {
    return (
        <View style={{margin:'7px 0 3px'}}>
            <Text style={[styles.headingSmall, styles.center]}>{number}</Text>
            <Text style={[styles.headingSmall, styles.center]}>{text}</Text>
        </View>
    )
}
const Paragraph = ({number,text,style}) => {
    return (
        <View style={[{flexDirection: 'row', marginTop:0, flexWrap:'no-wrap'},style]}>
            <Text style={[styles.paragraph, {marginRight:5, minWidth:15}]}>{number ? `${number}.` : ''}</Text>
            <Text style={[styles.paragraph, {flex: 1}]}>{text}</Text>
        </View>
    )
}

export const RentPdf = ({state, number}) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View>
                    <View style={{justifyContent:'center', alignItems: 'center', flexDirection:'row', marginBottom:50}}>
                        <Image source={LOGO} style={{margin:0,width:150}}/>
                    </View>
                    <View style={{margin:'20px 0'}}>
                        <Text style={[styles.title, styles.center]}>UMOWA NAJMU POJAZDU NR {state.number}/{new Date().getFullYear()}</Text>
                        <Text style={[styles.title, styles.center]}>zawarta w dniu {moment().locale("pl").format('LL')} w Katowicach pomiędzy:</Text>
                    </View>
                    <View style={{margin:'20px 0'}}>
                        <Text style={[styles.heading, {margin:'5px 0'}]}>Stronami umowy:</Text>
                        {
                            state.company === 'My Rent A Car Sp. z o.o.' &&
                                <>
                                    <Text style={[styles.heading]}>My Rent A Car Sp. z o.o. z siedzibą w Katowicach</Text>
                                    <Text style={[styles.heading]}>adres siedziby : Sowińskiego 4, 40-018 Katowice</Text>
                                    <Text style={[styles.heading]}>NIP: 9542828220 REGON: 389397042</Text>
                                    <Text style={[styles.heading]}>wpisana do Krajowego Rejestru Sądowego pod nr KRS: 0000910448</Text>
                                </>
                        }
                        {
                            state.company === 'MK Sp. z o.o.' &&
                            <>
                                <Text style={[styles.heading]}>MK Sp. z o.o. z siedzibą w Katowicach</Text>
                                <Text style={[styles.heading]}>adres siedziby : Sowińskiego 4, 40-018 Katowice</Text>
                                <Text style={[styles.heading]}>NIP: 5562767413 REGON: 366629533</Text>
                                <Text style={[styles.heading]}>wpisana do Krajowego Rejestru Sądowego pod nr KRS: 0000665279</Text>
                            </>
                        }
                        <Text style={[styles.heading]}>reprezentowana przez pełnomocnika: {state.reprezentant},</Text>
                        <Text style={[styles.heading]}>zwana w dalszej części Wynajmującym </Text>
                    </View>
                    {
                        state.clientType === 'Konsument' &&
                        <View>
                            <Text style={[styles.heading]}>a {state.clientSex} {state.clientName} PESEL: {state.clientPesel}</Text>
                            <Text style={[styles.heading]}>{state.clientSex === 'Panem' ? 'zamieszkującym' : 'zamieszkującą'} przy {state.adress}, {state.postCode} {state.city},</Text>
                            <Text style={[styles.heading]}>nr telefonu: {state.clientPhoneNumber}</Text>
                        </View>
                    }
                    {
                        state.clientType === 'Działalność gospodarcza' &&
                        <View>
                            <Text style={[styles.heading]}>a {state.clientSex} {state.clientName}</Text>
                            <Text style={[styles.heading]}>prowadzącą działalność gospodarczą pod firmą: {state.companyName}</Text>
                            <Text style={[styles.heading]}>wpisaną do Centralnej Ewidencji Działalności Gospodarczej, o nr REGON: {state.companyRegon} NIP: {state.companyNip}</Text>
                            <Text style={[styles.heading]}>z siedzibą w {state.city}.</Text>
                            <Text style={[styles.heading]}>Adres siedziby : {state.adress}, {state.postCode} {state.city}</Text>
                        </View>
                    }
                    {
                        state.clientType === 'Spółka' &&
                        <View>
                            <Text style={[styles.heading]}>a Spółką {state.companyName}</Text>
                            <Text style={[styles.heading]}>z siedzibą w {state.city}.</Text>
                            <Text style={[styles.heading]}>Adres siedziby: {state.adress}, {state.postCode} {state.city}</Text>
                            <Text style={[styles.heading]}>NIP: {state.companyNip}, REGON: {state.companyRegon}</Text>
                            <Text style={[styles.heading]}>wpisaną do Krajowego Rejestru Sądowego pod nr KRS: {state.companyKrs}</Text>
                            <Text style={[styles.heading]}>reprezentowaną przez {state.clientSex2} {state.clientName}</Text>
                        </View>
                    }
                    <View style={{marginTop: 60}}>
                        <ParagraphHeading number="§1" text="Przedmiot umowy najmu" />
                        <Paragraph number="1" text="Wynajmujący oddaje Najemcy do odpłatnego używania samochód osobowy opisany w § 1 pkt. 2 niniejszej umowy, a Najemca pojazd ten przyjmuje w najem i zobowiązuje się do zapłaty czynszu umownego na rzecz Wynajmującego w kwocie i na zasadach określonych w niniejszej umowie"/>
                        <Paragraph number="2" text={`Przedmiotem niniejszej umowy najmu jest samochód osobowy marki ${state.brand} model ${state.model} wyprodukowany w roku ${state.year} o numerze VIN nadwozia ${state.vin} numer rejestracyjny pojazdu ${state.plateNumber} na czas ${state.contractTime === 'określony' ? `określony, od dnia: ${moment(state.contractTimeFrom).locale("pl").format('LL')} godzina: ${moment(state.contractTimeFrom).locale("pl").format('LT')}, do dnia: ${moment(state.contractTimeTo).locale("pl").format('LL')} godzina: ${moment(state.contractTimeTo).locale("pl").format('LT')}.` : 'nieokreślony.'} `}/>
                        <Paragraph number="3" text="Wynajmujący oświadcza, że przedmiot najmu opisany w § 1 pkt. 2  jest sprawny i zdatny do użytku."/>
                        <Paragraph number="4" text={`Strony umowy zgodnie ustalają na wypadek pojawienia się ewentualnych roszczeń odszkodowawczych Wynajmującego z tytułu utraty lub uszkodzenia przedmiotu najmu, że rynkowa wartość pojazdu stanowiącego przedmiot umowy najmu na dzień podpisania niniejszej umowy jest następująca: ${parseNumber(state.carPrice)} zł, słownie: ${changePriceToString(state.carPrice)}.`}/>
                        <Paragraph number="5" text="Najemca oświadcza, że zapoznał się ze stanem technicznym przedmiotu najmu, jego wyposażeniem i stopniem zużycia i nie zgłasza do niego żadnych zastrzeżeń."/>
                        <Paragraph number="6" text="Wszelkie uwagi dotyczące stanu Samochodu, Najemca obowiązany jest zgłosić Wynajmującemu najdalej  w chwili protokolarnego wydania przedmiotu najmu pod rygorem braku możliwości powoływania się na okoliczności związane z ewentualnym złym lub niewłaściwym stanem przedmiotu najmu w terminie późniejszym. "/>
                        <Paragraph number="7" text="Wydanie przedmiotu najmu zostanie stwierdzono protokołem wydania przedmiotu najmu, który stanowi załącznik nr 1 do niniejszej umowy i zarazem jej integralną część."/>
                        <Paragraph number="8" text="Wynajmujący oświadcza, że posiada tytuł prawny do  przedmiotu najmu opisanego w § 1 pkt. 2 niniejszej umowy i że pojazd ten jest wolny od wad prawnych i technicznych."/>
                        <ParagraphHeading number="§2" text="Wydanie i zwrot przedmiotu najmu" />
                        <Paragraph number="1" text={`Wynajmujący oświadcza, że zobowiązuje się wydać Najemcy opisany w §1 przedmiot najmu w wyznaczonym terminie do dnia ${moment(state.realaseDate).locale("pl").format('LL')}. w miejscu: ${state.realasePlace}, a Najemca zobowiązuje się przyjąć przedmiot najmu na czas trwania umowy.`}/>
                        <Paragraph number="2" text="Najemca ma prawo wykonywać przedmiot niniejszej umowy najmu za czynsz najmu określony w niniejszej umowie wyłącznie na terytorium Rzeczypospolitej Polskiej. "/>
                        <Paragraph number="3" text={`Najemca wyłącznie za pisemną zgodą Wynajmującego może korzystać z przedmiotu najmu na terenie innych Państw Unii Europejskiej , co będzie wiązało się z dodatkową opłatą do czynszu najmuj za każdy dzień korzystania z przedmiotu najmu poza granicami RP oraz konieczność wpłacenia zwrotnej  kaucji na zabezpieczenie ewentualnych roszczeń Wynajmującego  w razie utraty lub zniszczenia pojazdu  w kwocie: ${parseNumber(state.oplataOutsideUE)} zł, słownie: ${changePriceToString(state.oplataOutsideUE)}.`}/>
                        <Paragraph number="4" text={`Brak pisemnej zgody Wynajmującego na korzystanie z przedmiotu najmu poza granicami kraju lub brak wpłaty kaucji, o  której mowa w § 2 pkt. 3 niniejszej umowy, uprawnia Wynajmującego do natychmiastowego rozwiązania niniejszej umowy najmu oraz naliczenia kary umownej w kwocie: ${parseNumber(state.oplataOutsidePL)} zł. za każde naruszenie, co nie pozbawia Wynajmującego możliwości dochodzenia odszkodowania z tytułu wynikłej stąd szkody na zasadach ogólnych Kodeksu Cywilnego w wysokości przenoszącej wysokość zastrzeżonej kary umownej.`}/>
                        <Paragraph number="5" text={`Strony umowy zgodnie postanawiają, że osobą faktycznie korzystającą z umowy najmu będzie Najemca wskazany w niniejszej umowie${state.additionalClientName ? ` lub ${state.additionalClientSex} ${state.additionalClientName} PESEL: ${state.additionalClientPesel}.` : '.'}`}/>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View>
                    <View>
                        <Paragraph number="6" text={`Najemca nie może powierzyć przedmiotu najmu innej osobie niż osoba wskazana w pkt. § 2 pkt. 5 niniejszej umowy pod rygorem możliwości  rozwiązania niniejszej umowy w trybie natychmiastowym i naliczenia kary umownej w kwocie: ${parseNumber(state.oplataZaInnaOsobeL)} zł netto, słownie: ${changePriceToString(state.oplataZaInnaOsobeL)} zł netto, co nie pozbawia Wynajmującego możliwości dochodzenia odszkodowania przenoszącego wysokość zastrzeżonej kary umownej do wysokości rzeczywiście wyrządzonej szkody.`}/>
                        <Paragraph number="7" text="Najemca oświadcza, że osoba korzystająca z przedmiotu najmu na podstawie niniejszej umowy ma aktualne uprawnienia do kierowania pojazdami mechanicznymi nie mniejsze niż na kat. B jak również ma skończone co najmniej 25 lat."/>
                        <Paragraph number="8" text={`W sytuacji stwierdzenia, że z przedmiotu Najmu korzysta osoba, która nie ma ukończonych 25 lat lub nie posiada aktualnych uprawnień do kierowania pojazdami bez względu na przyczynę, Wynajmujący ma prawa do natychmiastowego rozwiązania niniejszej umowy najmu jak również naliczenia od Najemcy kary umownej w wysokości: ${parseNumber(state.oplataZa25Lat)}, słownie: ${changePriceToString(state.oplataZa25Lat)}, co nie pozbawia Wynajmującego możliwości dochodzenia odszkodowania przenoszącego wysokość zastrzeżonej w tym wypadku kary umownej.`}/>
                        <Paragraph number="9" text="Najemca zobowiązuje się, że osoba wykonująca niniejszą umowę – korzystający z pojazdu, nie będzie prowadził pojazdu pod wpływem alkoholu, substancji odurzających czy leków wpływających na sprawność psychofizyczną kierowcy jak również nie będzie korzystał z przedmiotu najmu do innych celów niż przewóz osób."/>
                        <Paragraph number="10" text={`Najemca będzie miał prawo przewozić pasażerów pojazdem stanowiącym przedmiot najmu wyłącznie w ilości odpowiadającej przystosowaniu fabrycznemu  przedmiotu najmu do przewozu określonej liczby osób.`}/>
                        <Paragraph number="11" text={`Naruszenie postanowień z § 2 pkt. 9 i 10 niniejszej umowy uprawnia Wynajmującego do natychmiastowego rozwiązania umowy najmu i naliczenia kary umownej w wysokości: ${parseNumber(state.naruszenie9i10)} zł, słownie: ${changePriceToString(state.naruszenie9i10)}, co nie pozbawia Wynajmującego możliwości dochodzenia odszkodowania przenoszącego wysokość zastrzeżonej w tym wypadku kary umownej. `}/>
                        <Paragraph number="12" text="Przekazanie samochodu Najemcy do używania oraz zwrot samochodu Wynajmującemu przez Najemcę potwierdzają, odpowiednio: Protokół wydania i protokół zwrotu przedmiotu najmu. Wzór protokołu stanowi załącznik do niniejszej umowy i zarazem jego integralną część. "/>
                        <ParagraphHeading number="§3" text="Czas trwania umowy najmu i kaucja" />
                        <Paragraph number="1" text={`Niniejsza umowa zostaje zawarta na czas oznaczony tj. od dnia ${moment(state.signContractFrom).locale("pl").format('LL')} godz. ${moment(state.signContractFrom).locale("pl").format('LT')} do dnia ${moment(state.signContractTo).locale("pl").format('LL')} godz. ${moment(state.signContractTo).locale("pl").format('LT')}.`}/>
                        <Paragraph number="2" text={`Miejscem wydania i zwrotu pojazdu po zakończonej umowie najmu jest: ${state.returnPlace}`}/>
                        <Paragraph number="3" text={`Osobą upoważnioną z ramienia Wynajmującego do wydania i odbioru przedmiotu Najmu jest: ${state.returnPerson}.`}/>
                        <Paragraph number="4" text="Brak podpisania pisemnego protokołu zwrotu przedmiotu najmu przez Najemcę jest równoznaczny z porzuceniem przedmiotu najmu przez Najemcę i uprawnia Wynajmującego do jednostronnego sporządzenia protokołu zwrotu pojazdu po zakończonej umowie, co wywołuje ten skutek, że na wypadek późniejszych roszczeń Wynajmującego, Najemca nie będzie mógł powoływać się na okoliczności przeciwne niż te, które wynikają z treści pisemnego protokołu. "/>
                        <Paragraph number="5" text="Strony postanawiają, że czas trwania niniejszej umowy najmu może być przedłużony za pisemną zgodą Wynajmującego, jednak zamiar  zmiany terminu zwrotu pojazdu  przez Najemcę musi być  zgłoszony niezwłocznie, nie później niż 48 godzin przed upływem terminu zwrotu pojazdu, który określono w niniejszej umowie, jednocześnie  brak zgłoszenia zamiaru przedłużenia okresu wynajmu pojazdu i niezwrócenie go w ciągu 3 godzin od momentu zakończenia umowy zgodnie z terminem i datą wskazaną w niniejszej umowie będzie  traktowane, jako przywłaszczenie i zostanie zgłoszone organom ścigania. "/>
                        <Paragraph number="6" text={`Każde przedłużenie przez Najemcę najmu, bez pisemnej zgody Wynajmującego, o więcej niż 1 godzinę ponad dobę określoną w §1, powoduje naliczenie opłaty za kolejną, pełną  dobę wynajmu jak również uprawnia Wynajmującego do naliczenia kary umownej w wysokości: ${parseNumber(state.oplataZaPrzedluzenie)} zł, słownie: ${changePriceToString(state.oplataZaPrzedluzenie)}. co nie pozbawia Wynajmującego prawa dochodzenia odszkodowania na zasadach ogólnych Kodeksu Cywilnego, przenoszącego wysokość zastrzeżonej kary umownej.`}/>
                        <Paragraph number="7" text={`Najemca zobowiązany jest wpłacić kaucję w wysokości ${parseNumber(state.kaucja)} zł, słownie ${changePriceToString(state.kaucja)} gotówką lub przelewem na nr rachunku ${state.company === 'MK Sp. z o.o.' ? '59 1140 2004 0000 3102 8164 8646' : '51 1140 2004 0000 3202 8146 0658'}. podany poniżej, minimum 24  godziny przed wydaniem pojazdu stanowiącego przedmiot najmu, przy czym brak wpłaty kaucji uprawnia Wynajmującego do odmowy wydania pojazdu.`}/>
                        <Paragraph number="8" text="Kaucja, o której mowa w § 3 pkt. 7 niniejszej umowy stanowi zabezpieczenie ewentualnych roszczeń Wynajmującego wobec Najemcy i podlega zwrotowi w terminie 72 godzin od podpisania protokołu zwrotu pojazdu, przy czym jeżeli okaże się, że przedmiot najmu w dniu zwrotu pojazdu uległ pogorszeniu lub zniszczeniu to Wynajmujący ma prawo potrącić z tej kaucji kwotę niezbędną do przywrócenia stanu pojazdu do stanu z dnia jego wydania, na co Najemca wyraża zgodę."/>
                        <ParagraphHeading number="§4" text="Zasady eksploatowania samochodu przez najemcę" />
                        <Paragraph number="1" text="Najemca zobowiązany jest korzystać z przedmiotu umowy najmu  z należytą starannością i zgodnie z jego przeznaczeniem. Wszelkie obowiązki nałożone na Najemcą, wymienione w niniejszym umowie, obciążają także osoby trzecie korzystające z Samochodu za zgodą lub wiedzą Najemcy, przy czym Najemca odpowiada za zachowanie tych osób jak za własne działania i zaniechania i ponosi odpowiedzialność odszkodowawczą wobec Wynajmującego do pełnej wysokości wyrządzonej szkody za działania lub zaniechania osób, którym pojazd będący przedmiotem niniejszej umowy powierzył."/>
                        <Paragraph number="2" text="Najemca jak i inne osoby, który powierzył korzystanie z przedmiotu najmu, zobowiązany jest do bezwzględnego przestrzegania przepisów ruchu drogowego i zasad bezpieczeństwa na drogach publicznych jak i na drogach bądź parkingach stanowiących tereny prywatne. "/>
                        <Paragraph number="3" text="Najemca ponosi wobec Wynajmującego pełną odpowiedzialność odszkodowawczą do pełnej wysokości wyrządzonej szkody, za wszelkie szkody w przedmiocie pojazdu, w szczególności z tytułu utraty, uszkodzenia, zniszczenia, dewastacji pojazdu lub zmniejszenia jego waloru użytkowego lub estetycznego, jak również ponosi pełną odpowiedzialność odszkodowawczą z tytułu wyrządzenia ewentualnych szkód osobom trzecim w związku z korzystaniem z pojazdu i zakres tej odpowiedzialności odnosi się zarówno do szkód w mieniu osób trzecich jak również szkód na osobach (życie, zdrowie). "/>
                        <Paragraph number="4" text="Najemca zobowiązany jest zwrócić Wynajmującemu przedmiot najmu, po upływie okresu najmu, który został określony w niniejszej umowie w takim  stanie w jakim go otrzymał od Wynajmującego tj. zatankowany do pełna, oraz umyty na zewnątrz i czysty wewnątrz,  zgodnie z Protokołem wydania) wraz z wszelkimi dokumentami dotyczącymi pojazdu i kompletem kluczy, wyposażeniem dodatkowym oraz kompletem kół, w tym z kołem zapasowym jeśli w takie był wyposażony pojazd."/>
                        <Paragraph number="5" text="Jeżeli Najemca zwróci samochód uszkodzony lub prezentujący ponadnormatywne zużycie, wówczas zobowiązany jest do poniesienia kosztów przywrócenia pojazdu do stanu z dnia zawarcia umowy najmu zgodnie z zapisami zawartymi w   dokumencie – „Przewodnik zwrotu pojazdu”, który stanowi integralną część niniejszej umowy i jest dostępny  na stronie: https://pzwlp.pl/storage/files/media/do%20pobrania/PZWLP_Przewodnik_Zwrotu_Pojazdow_2011.pdf i wg. kosztów rzeczywistych naprawy w autoryzowanej stacji obsługi."/>
                        <Paragraph number="6" text="nienależytym stanie, w szczególności np. uszkodzony, z uszkodzoną felgą, oponą, brudny, niezatankowany itp., to Wynajmujący ma prawo potrącić kwotę niezbędną do przywrócenia Samochodu do należytego stanu z kaucji, o której mowa w niniejszej umowie."/>
                        <Paragraph number="7" text="Ceny poszczególnych elementów składających się na koszty  przywrócenia samochodu do stanu w jakim się on znajdował w chwili jego przekazania Najemcy wskazane będą wg cennika autoryzowanego serwisu i zgodnie z wolą Stron niniejszej umowy do wyceny szkód  będą brane pod uwagę wyłącznie ceny części nowych, które odpowiadają marce pojazdu stanowiącego przedmiot umowy najmu."/>
                        <Paragraph number="8" text="Najemca zobowiązany jest w szczególności do:"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="a) przestrzegania przepisów prawa podczas używania Samochodu oraz zachowania należytej staranności,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="b) zapłaty mandatów, opłat za postój w strefie płatnego parkowania oraz innych kar wynikających z ewentualnego naruszenia prawa w związku z korzystaniem z samochodu; za proces przekazania pisemnej informacji wyjaśniającej odpowiednim organom Wynajmujący naliczy opłatę w wysokości 100zł netto."/>
                        <Paragraph number="" style={{paddingLeft:10}} text="c) powtrzymania się od wykorzystywania przedmiotu najmu do przewozu substancji zabronionych przepisami prawa co do posiadania czy rozpowszechniania"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="d) powtrzymania się od wykorzystywania przedmiotu najmu do przewozu środków żrących, łatwopalnych, materiałów wybuchowych, substancji szkodliwych dla zdrowia, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="e) powtrzymania się od wykorzystywania przedmiotu najmu do przewozu substancji lub rzeczy, które mogą spowodować utrwalenie zapachów wewnątrz pojazdów, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="f) powstrzymania się od przewozu zwierząt w przedmiocie najmu "/>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View>
                    <View>
                        <Paragraph number="" style={{paddingLeft:10}} text="g) powstrzymania się od palenia tytuniu w przedmiocie najmu "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="h) Najemca zobowiązuje się do należytego zabezpieczenia przedmiotu najmu na czas postoju przed dostępem osób niepowołanych, aktami wandalizmy i działaniem warunków atmosferycznych."/>
                        <Paragraph number="" style={{paddingLeft:10}} text="i) Najemca zobowiązuje się nie pozostawiać w pojeździe kluczyków, dokumentów związanych z przedmiotem najmu, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="j) Najemca zobowiązuje się do niezwłocznego (nie dalej niż w terminie 2 godzin),  powiadomienia Wynajmującego  o utracie lub zniszczeniu pojazdu, w szczególności o jego kradzieży, aktach wandalizmu, zdarzeniach drogowych z udziałem pojazdu, który stanowi przedmiot niniejszej umowy najmu, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="k) Najemca zobowiązuje się do wezwania Policji w sytuacji zaistnienia zdarzenia drogowego z udziałem pojazdu będącego przedmiotem najmu (w szczególności wypadku, kolizji, szkody parkingowej) jak również do każdorazowego i natychmiastowego wezwania Policji w sytuacji kradzieży pojazdu, aktów wandalizmu, włamania do pojazdu czy zaginięcia dokumentów dotyczących pojazdu stanowiącego przedmiot najmu, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="l) Najemca zobowiązuje się: "/>
                        <Paragraph number="" style={{paddingLeft:25}} text="- w przypadku zniszczenia lub kradzieży przedmiotu umowy i odmowy pokrycia tej szkody przez ubezpieczyciela w ramach polisy,  zapłacić na rzecz Wynajmującego w terminie 14 dni od dnia wezwania przez Wynajmującego kwotę stanowiącą równowartość rzeczywiście wyrządzonej szkody, "/>
                        <Paragraph number="" style={{paddingLeft:25}} text="- w przypadku kradzieży wynajętego samochodu wraz z dokumentami i kluczykami pokryć koszty jego utraty w całości na rzecz Wynajmującego w terminie 14 dni od wezwania przez Wynajmującego do zapłaty tej kwoty."/>
                        <Paragraph number="9" text="Najemca zobowiązany jest do ponoszenia kosztów związanych z bieżącą eksploatacją Samochodu. Najemca nie może dokonywać żadnych napraw Samochodu, za wyjątkiem zaistnienia zdarzenia uniemożliwiającego dalszą jazdę samochodem. W takiej sytuacji Najemca zobowiązany jest niezwłocznie zawiadomić Wynajmującego o tej okoliczności i uzyskać jego pisemną zgodę na naprawę."/>
                        <Paragraph number="10" text="Najemca oświadcza, że przed podpisaniem niniejszej umowy zapoznał się z zakresem polis OC i AC pojazdu stanowiącego przedmiot niniejszej umowy, warunkami ubezpieczenia i posiada pełną świadomość co do ryzyk, które nie obejmuje ubezpieczenie, a tym samym zobowiązuje się do pokrycia w całości szkody, w sytuacji gdy nie będzie ona w przypadku jej wystąpienia pokryta z polis OC i AC."/>
                        <Paragraph number="11" text="Najemca oświadcza, że przyjął do wiadomości, że polisy OC i AC związane z przedmiotem pojazdu nie obejmują następujących ryzyk:"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- uszkodzenia lub utraty  pojazdu, wystąpienia szkody całkowitej,  jeżeli osoba kierująca była w stanie nietrzeźwym, pod wpływem narkotyków lub środków odurzających, lub bez ważnego prawa jazdy, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- uszkodzenia pojazdu w przypadku przekroczenia dozwolonej prędkości, o co najmniej 20 km/h lub w razie dopuszczenia się innego rażącego naruszenia przepisów ruchu drogowego,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- przypadków  gdy kierowca  oddalił się lub zbiegł z miejsca wypadku, kolizji, zdarzenia drogowego,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- w przypadku utraty pojazdu stanowiącego przedmiot niniejszej umowy  wskutek  kradzieży pojazdu z dokumentami i kluczykami lub kradzieży pojazdu, w którym nie uruchomiono wszystkich urządzeń antykradzieżowych, "/>
                        <Paragraph number="12" text="Najemca przyjmuje do wiadomości i wyraża zgodę na to, że szkody częściowe rozliczane w ciężar polisy Auto Casco mogą powodować obciążenie finansowe Najemcy opłatą za udział własny w szkodzie, odpowiednią kwotą, zgodną z OWU, z którymi Najemca zapoznał się podpisując niniejszą umowę."/>
                        <Paragraph number="13" text="Najemca zobowiązany jest do niezwłocznego informowania Wynajmujące o każdej sytuacji mającej wpływ na jego zdolności płatnicze zobowiązań finansowych wynikających z niniejszej umowy."/>
                        <ParagraphHeading number="§5" text="" />
                        <Paragraph number="1" text="Wynajmujący oświadcza, że pojazd stanowiący przedmiot niniejszej umowy najmu posiada pełny zakres ubezpieczenia OC i AC i, że polisy OC i AC są aktualne i należycie opłacone."/>
                        <Paragraph number="2" text="Wynajmujący oświadcza, że pojazd będący przedmiotem niniejszej umowy posiada aktualne badania techniczne, jest sprawny i nadaje się do ruchu."/>
                        <Paragraph number="3" text="Wynajmujący zobowiązuje się wydać na czas trwania niniejszej umowy pojazd będący sprawny technicznie, wolny od wad prawnych i technicznych, z pełnym zbiornikiem paliwa, z uzupełnionymi płynami – oleje, płynem  do spryskiwaczy szyb odpowiadającym porze roku."/>
                        <Paragraph number="4" text="Wynajmujący zobowiązuje się do prawidłowego wystawienia faktury VAT z tytułu pobranego czynszu najmu pojazdu. "/>
                        <Paragraph number="5" text="Wynajmujący oświadcza, że jest czynnym płatnikiem podatku VAT. "/>
                        <Paragraph number="6" text="Wynajmujący jest uprawniony do naliczania odsetek w wysokości ustawowej za każdy dzień opóźnienia lub zwłoki z zapłatą czynszu najmu."/>
                        <Paragraph number="7" text="Wynajmujący zastrzega sobie prawo do rozwiązania niniejszej umowy przed upływem okresu, na który została zawarta  w następujących sytuacjach:"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- zaniechania przez Najemcę zwrotu pojazdu w oznaczonym w niniejszej umowy terminie,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- użytkowania przedmiotu najmu przez Najemcę niezgodnie z postanowieniami niniejszej umowy,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- nieterminowej zapłaty czynszu najmu, jeżeli zaległość w płatności czynszu najmu przekracza 72 godziny, przy czym za datę i godzinę zapłaty czynszu najmu strony ustalają datę uznania rachunku bankowego Wynajmującego lub datę i godzinę przyjęcia gotówki,"/>
                        <Paragraph number="" style={{paddingLeft:10}}v text="- powierzenia przedmiotu Najmu innej niż najemca osobie, nie wskazanej w niniejszej umowie bez pisemnej zgody Wynajmującego,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- korzystania z pojazdu poza terytorium RP bez pisemnej zgody Wynajmującego,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- korzystania z przedmiotu najmu niezgodnie z jego przeznaczeniem, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- nagłej potrzeby Wynajmującego wynikającej z konieczność przeprowadzenia badań technicznych, konieczności przestawienia pojazdu do oględzin organom lub ubezpieczycielom,  "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- uzasadnionej ekonomicznie konieczności sprzedaży przedmiotu najmu przez Wynajmującego,"/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- ogłoszenia przez Wynajmującego upadłości lub postępowania restrukturyzacyjnego, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- wyrządzenia szkody w przedmiocie najmu przez Najemcę lub osoby trzecie i konieczności podjęcia procedury likwidacji tej szkody bądź naprawy pojazdu, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- stwierdzenia wady pojazdu, "/>
                        <Paragraph number="" style={{paddingLeft:10}} text="- pozyskania informacji o pogorszeniu się sytuacji finansowej Najemcy, która uzasadniałaby ryzyko nie wywiązania się przez Najemcę ze zobowiązań finansowych wobec Wynajmującego, które wynikają z niniejszej umowy."/>
                        <ParagraphHeading number="§6" text="Czynsz najmu i płatności" />
                        <Paragraph number="1" text={`Najemca zapłaci Wynajmującemu czynsz najmu w wysokości ${parseNumber(state.czynsz)} zł netto, słownie: ${changePriceToString(state.czynsz)} zł netto za każdy rozpoczęty dzień trwania umowy najmu z zastrzeżeniem § 6 pkt. 2. Limit miesięczny kilometrów wynosi ${state.limitKM} km. Opłata za nadprzebieg wynosi ${parseNumber(state.oplataZaNadprzebieg)} zł netto, słownie: ${changePriceToString(state.oplataZaNadprzebieg)} zł netto za 1km`}/>
                        <Paragraph number="2" text={`Jeżeli niniejsza umowa najmu jest zawarta na czas dłuższy niż jeden miesiąc kalendarzowy, to Najemca będzie płacił na rzecz Wynajmującego czynsz Najmu z góry w wysokości: ${parseNumber(state.czynszZGory)} zł netto, słownie: ${changePriceToString(state.czynszZGory)} zł netto miesięcznie, najdalej do 10-tego dnia każdego miesiąca kalendarzowego, a jeśli niniejsza umowa trwająca ponad miesiąc, zostanie zakończona przed upływem kolejnego pełnego miesiąca kalendarzowego to czynsz najmu będzie rozliczony proporcjonalnie do faktycznego czasu trwania umowy najmu z uwzględnieniem zapisów przewidzianych w § 4 pkt. 1 w odniesieniu do ostatniego miesiąca rozliczeniowego.`}/>
                        <Paragraph number="3" text="Czynsz najmu będzie płatny jednorazowo w całości w dniu wydania przedmiotu najmu, jeśli umowa zostanie zawarta na czas krótszy niż jeden miesiąc, natomiast w przypadku umów najmu zawartych na czas dłuższy niż jeden miesiąc, Najemca zobowiązany będzie do zapłaty czynszu najmu w comiesięcznych ratach na rachunek wskazany w wystawionej przez Wynajmującego fakturze VAT, w terminie 7 dni od dnia doręczenia faktury."/>
                        <Paragraph number="4" text="Wynajmujący będzie wystawiał faktury z tytułu miesięcznych czynszów najmu najdalej do 5-tego dnia każdego miesiąca."/>
                        <Paragraph number="5" text={`Wystawiona przez Wynajmującego faktura VAT nie wymaga podpisu, a strony uzgadniają, że faktury te będą doręczane elektronicznie najemcy  na adres e-mail: ${state.email}`}/>
                        <Paragraph number="6" text="Za dzień dokonania płatności przez Najemcę uznaje się dzień uznania rachunku bankowego Wynajmującego. "/>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View>
                    <View>
                        <ParagraphHeading number="§7"/>
                        <Paragraph number="1" text="Po zakończeniu najmu Najemca zwróci Wynajmującemu  pojazd będący przedmiotem umowy w stanie niepogorszonym ponad normalne zużycie."/>
                        <Paragraph number="2" text="W przypadku zwrotu przez Najemcę przedmiotu umowy przed terminem określonym w umowie, Wynajmujący nie będzie zobowiązany do zwrotu wpłaconych tytułem czynszu najmu środków przez Najemcę."/>
                        <Paragraph number="3" text="Strony zobowiązują się do sporządzenia pisemnego protokołu zwrotu przedmiotu najmu po zakończeniu umowy najmu bez względu na sposób rozwiązania umowy."/>
                        <Paragraph number="4" text="Najemca zobowiązany jest do pokrycia na rzecz Wynajmującego wszelkich kosztów związanych z wyrządzeniem szkód w pojeździe oraz z koniecznością jego czyszczenia w razie stwierdzenia takiej konieczności w terminie 7 dni od wezwania do zapłaty otrzymanego od Wynajmującego. "/>
                        <Paragraph number="5" text="Najemca zobowiązany jest do zgłoszenia Wynajmującemu wszelkich okoliczności, które wskazywałyby na ukrytą wadę pojazdu, która miałaby wpływ na bezpieczeństwo pasażerów pojazdu. "/>
                        <ParagraphHeading number="§8"/>
                        <Paragraph number="1" text="Wynajmujący zobowiązany jest do zapewnienia pełnej obsługi serwisowej przedmiotu najmu  obejmującej swym zakresem okresowe przeglądy techniczne i przeglądy  wymagane przez producenta, a także do zapewnienia napraw gwarancyjnych i pogwarancyjnych, wymiany części i płynów eksploatacyjnych, wymianę żarówek, zużytych piór wycieraczek oraz do  sezonowej wymiany opon i ich przechowywania."/>
                        <Paragraph number="2" text="Strony niniejszej umowy zgodnie postanawiają, że pojazd stanowiący przedmiot umowy najmu  wyposażony może być wyposażony w  lokalizator GPS rejestrujący w czasie rzeczywistym położenie samochodu, trasę przez niego przebytą, parametry przejazdu oraz inne dane związane z przemieszczaniem się pojazdu oraz zapisujący wszystkie powyższe dane i umożliwiający ich odtworzenie w dowolnym czasie, na co Najemca wyraża zgodę."/>
                        <ParagraphHeading number="§9"/>
                        <Paragraph number="1" text="Najemca ma prawo odstąpić od niniejszej umowy gdy Wynajmujący opóźnia się z wydaniem przedmiotu umowy o 5 dni roboczych w stosunku do terminów wskazanych w umowie w przypadku umów zawartych na okres powyżej jednego miesiąca, a w przypadku umów zawartych na okres do jednego miesiąca, jeżeli opóźnienie z wydaniem przedmiotu najmu wynosi dłużej niż 8 godzin. "/>
                        <Paragraph number="2" text="Najemca ma prawo odstąpić od niniejszej umowy gdy wydany samochód nie spełnia wymagań i standardów określonych w umowie tj. jest niesprawny technicznie, nie posiada aktualnej polisy OC i AC, nie posiada aktualnych badań technicznych, posiada wady techniczne uniemożliwiające bezpieczne poruszanie się pojazdem. "/>
                        <Paragraph number="3" text="W przypadkach określonych w § 7 pkt. 1 i 2 tego paragrafu Najemcy nie przysługują jakiekolwiek roszczenia odszkodowawcze."/>
                        <Paragraph number="4" text="Oświadczenie o odstąpieniu od umowy powinno nastąpić w formie pisemnej pod rygorem nieważności."/>
                        <Paragraph number="5" text="Najemca nie może bez pisemnej zgody Wynajmującego dokonać cesji praw i obowiązków wynikających z niniejszej umowy."/>
                        <Paragraph number="6" text="Wynajmujący może dokonać cesji praw i obowiązków wynikających z niniejszej umowy na inny podmiot lub osobę, z tym zastrzeżeniem że jest zobowiązany do pisemnego powiadomienia Najemcy o powyższym fakcie."/>
                        <Paragraph number="7" text="Wynajmujący może sprzedać wierzytelność wynikającą z niniejszej umowy na rzecz innej osoby lub podmiotu. "/>
                        <Paragraph number="8" text="Wynajmujący i Najemca zobowiązani są do wzajemnego informowania się zmianach adresu dla doręczeń w formie pisemnej pod rygorem uznania, że doręczenia na wskazany w niniejszej umowie adres są skuteczne. "/>
                        <ParagraphHeading number="§10"/>
                        <Paragraph number="1" text="W sytuacji gdy Najemca zwróci po zakończeniu umowy przedmiot najmu z nowymi uszkodzeniami (powstałymi podczas trwania niniejszej umowy), to Wynajmujący obciąży Najemcę kwotą równoważną kosztom potrzebnym do naprawy uszkodzonego pojazdu a także obciąży go kosztami przestoju pojazdu tytułem utraconych korzyści, które będą obliczane w następujący sposób:   dobowa stawka czynszu najmu pomnożona przez liczbę dni potrzebnych do naprawy pojazdu."/>
                        <Paragraph number="2" text="W sytuacji gdy Najemca nie powiadomi wynajmującego niezwłocznie o uszkodzeniu pojazdu będącego przedmiotem najmu, to  Najemca niezależnie od decyzji ubezpieczyciela w sprawie wypłaty odszkodowania będzie zobowiązany do pokrycia wynikłej ze zdarzenia szkody w całości na rzecz Wynajmującego, niezależnie od zastrzeżonych w niniejszej umowie kar umownych. "/>
                        <Paragraph number="3" text="W przypadku kradzieży pojazdu Najemca zobowiązany jest do  niezwłocznego poinformowania  o tym fakcie Wypożyczającego oraz do  zwrotu  kluczyków  i dokumentów od pojazdu, w przeciwnym wypadku Najemca zostanie obciążony kwotą równoważną wartości rynkowej wynajętego pojazdu, którą określono w niniejszej umowie."/>
                        <Paragraph number="4" text="Wynajmujący nie ponosi odpowiedzialności wobec Najemcy za rzeczy pozostawione w przedmiocie najmu. "/>
                        <ParagraphHeading number="§11"/>
                        <Paragraph number="1" text="Termin płatności kary umownej wynosi 7 dni kalendarzowych od dnia otrzymania wezwania do zapłaty."/>
                        <Paragraph number="2" text="Niezależnie od zapłaty kary umownej, Wynajmujący może dochodzić naprawienia szkody na zasadach ogólnych określonych w Kodeksie Cywilnym do pełnej wysokości wyrządzonej szkody."/>
                        <Paragraph number="3" text="Kary umowne podlegają sumowaniu na co Najemca wyraża zgodę podpisując niniejszą umowę. "/>
                        <Paragraph number="4" text="Roszczenia z tytułu kar umownych będą pokrywane w pierwszej kolejności z wynagrodzenia Wynajmującego należnego z tytułu czynszu, a dopiero potem z kaucji wpłaconej na zabezpieczenia należności."/>
                        <Paragraph number="5" text="Wynajmujący wg. swojego uznania może dokonać potrącenia kar umownych z wpłaconej przez Najemcę kaucji, na co Najemca wyraża zgodę."/>
                        <ParagraphHeading number="§12" text="Postanowienia końcowe"/>
                        <Paragraph number="1" text="W sprawach nieregulowanych niniejszą umową obowiązują przepisy Kodeksu Cywilnego, a w razie potrzeby innych przepisów powszechnie obowiązującego prawa."/>
                        <Paragraph number="2" text="Wszelkie zmiany niniejszej umowy wymagają formy pisemnej pod rygorem nieważności."/>
                        <Paragraph number="3" text="Umowę sporządzono w dwóch jednobrzmiących egzemplarzach po jednym dla każdej ze stron. "/>
                        <Paragraph number="4" text="Wszelkie załączniki do niniejszej umowy stanowią jej integralną część."/>
                        <Paragraph number="5" text="Załącznikami do niniejszej umowy są:"/>
                        <Paragraph style={{paddingLeft:10}} text="a) protokół wydania pojazdu Najemcy"/>
                        <Paragraph style={{paddingLeft:10}} text="b) protokół zwrotu pojazdu Wynajmującemu"/>
                        <Paragraph style={{paddingLeft:10}} text="c) Klauzula informacyjna RODO Wynajmującego"/>
                        <Paragraph style={{paddingLeft:10}} text="d) aktualny odpis z KRS Wynajmującego "/>
                        <Paragraph style={{paddingLeft:10}} text="e) aktualny odpis z KRS Najemcy – tylko w sytuacji gdy Najemcą jest spółka prawa handlowego wpisana do KRS lub zaświadczenie  z CEIDG"/>
                        <Paragraph style={{paddingLeft:10}} text="f) kopia polisy OC i AC pojazdu stanowiącego przedmiot najmu"/>
                        <Paragraph style={{paddingLeft:10}} text="g) kopia dowodu rejestracyjnego pojazdu stanowiącego przedmiot najmu "/>
                        <Paragraph number="6" text="Administratorem danych osobowych Najemcy w związku z podpisaniem niniejszej umowy jest My Rent A Car Sp. z o.o. z siedzibą w: Katowicach reprezentowana przez Prezesa Zarządu Mariusza Kołeckiego i Członka Zarządu Adama Bauzę. "/>
                        <Paragraph number="7" text="Dane osobowe Najemcy jak również dane osobowe osób wskazanych do wykonywania umowy najmu przez Najemcę będą wykorzystywane wyłącznie na potrzeby związane z wykonywaniem niniejszej umowy i wyłącznie w celu wykonywania niniejszej umowy. "/>
                        <Paragraph number="8" text="Strony zgodnie postanawiają, że dane wynikające z niniejszej umowy mogą być dalej powierzone organom ścigania, sądom powszechnym, organom administracji, pełnomocnikom zawodowym stron w osobach radców prawnych i adwokatów, jak również Ubezpieczycielom w zakresie zgłoszenia i likwidacji szkód jak i w wypłaty odszkodowania. "/>
                        <Paragraph number="9" text="Najemca podpisując niniejszą umowę oświadcza, że przed jej podpisaniem zapoznał się z klauzulą informacyjną RODO Wynajmującego i nie zgłasza do niej zastrzeżeń."/>
                    </View>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View>
                    <View>
                        <Paragraph number="10" text="Strony umowy przyjmują do wiadomości, że podanie danych osobowych, jest obowiązkowe na podstawie Rozporządzenia Ministra Finansów w sprawie wykonywania niektórych przepisów ustawy o podatku od towarów i usług oraz o podatku akcyzowym. "/>
                        <Paragraph number="11" text="Wynajmujący będzie przechowywał dane osobowe Najemcy i osób przez niego wskazanych do użytkowania pojazdu przez okres do 6 lat, jednak nie dłużej niż 12 miesięcy po upływie terminu przedawnienia roszczeń."/>
                        <Paragraph number="12" text="Wszelkie spory wynikające z niniejszej umowy będzie rozwiązywać Sąd właściwy miejscowo dla siedziby Wynajmującego. "/>
                        <Paragraph number="13" text="Umowę sporządzono wraz z załącznikami w dwóch egzemplarzach po jednym dla każdej ze stron."/>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-around', alignItems:'center', marginTop:70}}>
                        <View style={{flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Text style={[styles.paragraph]}>..................................................</Text>
                            <Text style={[styles.paragraph]}>[podpis Wynajmującego]</Text>
                        </View>
                        <View style={{flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <Text style={[styles.paragraph]}>..................................................</Text>
                            <Text style={[styles.paragraph]}>[podpis Najemcy]</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )};

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

Font.register({
    family: 'VerdanaBold',
    src:VERDANA_BOLD,
});

Font.register({
    family: 'Verdana',
    src:VERDANA_NORMAL,
});

const styles = StyleSheet.create({
    center:{
        textAlign:'center'
    },
    title:{
        fontFamily: 'VerdanaBold',
        fontSize:13,
    },
    paragraph:{
        fontFamily: 'Verdana',
        fontSize:7,
        margin:'0px 0'
    },
    headingSmall:{
        fontFamily: 'VerdanaBold',
        fontSize:8,
    },
    heading:{
        fontFamily: 'VerdanaBold',
        fontSize:9,
    },
    page: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding:30,
        paddingBottom:50
    },
});

export default RentPdf;
