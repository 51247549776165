import React from 'react';
import styled from 'styled-components';
import Title from "./title";
import {Box} from "./_atoms";
import {theme} from "../theme/theme";

const Block = ({title, children, ...rest}) => {

  return (
    <Box background="white" borderRadius="5px" {...rest}>
      <Box p="10px 15px" borderBottom={`1px solid rgba(0,0,0,0.1)`}>
        <Title third>{title}</Title>
      </Box>
      <Box height="max-content" p="10px 15px">
        {children}
      </Box>
    </Box>
  );
}

export default Block;
