export function monthDiff(fromDate) {
    if (fromDate === ''){
        return false
    }

    const d1 = new Date(fromDate)
    const d2 = new Date()
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();


    if(d2 > d1){
        return "Uwaga! Wybrana data jest wcześniejsza od dzisiejszej"
    }
    return months === 0 ?  false : `Uwaga! Data przekracza jeden miesiąc`
}