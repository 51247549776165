import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Modal from "../../../../../components/modal";
import {P} from "../../../../../components/p";
import Input from "../../../../../components/input";
import {StyledErrorMessage} from "../../../../../theme/styledComponents";
import Button from "../../../../../components/button";
import Spinner from "../../../../../components/spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faLock, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ChangePasswordContentBody} from "../../navigation/navigation";
import {db} from "../../../../../firebase/firebase";
import {connect} from "react-redux";
import {makeid} from "../../../../../theme/cookies";

function AllTemplates ({templates,deleteTemplate,chooseTemplate,onBack}) {
    const [query,setQuery] = useState('');
    return(
        <>
            <Modal title="Dostepne szablony" onBack={onBack}>
                <P>Wybierz szablon z którego chcesz skorzystać.</P>
                <Input label="Filtruj po nazwie" value={query} onChange={(e) => setQuery(e.target.value)}/>
                <StyledContent style={{}}>
                    {
                        templates.filter(item => query !== '' ? item.name.toUpperCase().includes(query.toUpperCase()) : true).sort(function(a, b){
                            if(a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                            if(a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
                            return 0;
                        }).map(item =>
                            <Template>
                                <TemplateName>{item.name}</TemplateName>
                                <div>
                                    <Icon onClick={() => deleteTemplate(item.id)}><FontAwesomeIcon icon={faTrash}/></Icon>
                                    <Icon onClick={() => chooseTemplate(item.id)}><FontAwesomeIcon icon={faDownload}/></Icon>
                                </div>
                            </Template>
                        )
                    }
                </StyledContent>
            </Modal>
        </>
    )
};

AllTemplates.propTypes = {
};

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}
export default connect(mapStateToProps)(AllTemplates);

const StyledContent = styled.div`
    margin-top:15px;
    max-height:400px;
    overflow-y:scroll;
    &::-webkit-scrollbar {
      display: none;
    }
`;

const Icon = styled.div`
  display: inline-block;
  font-size: ${({theme}) => theme.font.XS};
    opacity: 1;
    color: ${({theme}) => theme.thirdColor};
    margin-left: 10px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
      color: ${({theme}) => theme.primaryColor};
    }
`;

const TemplateName = styled.p`
  margin: 0px;
  font-size: ${({theme}) => theme.font.XS};
    opacity: 1;
    color: ${({theme}) => theme.secondColor};
     font-weight: 500;
`;

const Template = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
  background: rgb(240,240,240);
  padding:10px;
  border-radius: 3px;
  margin-bottom: 5px;
`;
