import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Navigation from "./navigation/navigation";
import { connect } from "react-redux";
import Dashboard from "../../../components/dashboard";
import {Redirect, Route} from "react-router-dom";
import OfferGenerator from "./content/offerGenerator/offerGenerator";
import Panel from "./content/motolead/panel/Panel";
import ProtectedRoute from "../../../components/protectedRoute";
import Users from "./content/settings/users/Users";
import WebsiteOffers from "./content/WebsiteOffers/WebsiteOffers";
import RentGenerator from "./content/rentGenerator/rentGenerator";
import General from "./content/settings/general/General";
import Tests from "./content/settings/tests/Tests";
import MRProfiles from "./content/motorata/profiles/MRProfiles";

function Worker (props) {
    const { dispatch,user } = props;

    return(
        <Dashboard>
            <Navigation/>
            <div style={{flex:'1 1',background:'rgb(240,240,240)',padding:'30px',overflowY:'scroll'}}>
                <Route path = '/panel/generator' component={OfferGenerator} />
                <Route path = '/panel/offers' component={WebsiteOffers} />
                <Route path = '/panel/motolead/dashboard' component={Panel} />
                <Route path = '/panel/motorata/profiles' component={MRProfiles} />
                <ProtectedRoute
                    path="/panel/my-rent"
                    component={RentGenerator}
                    isAuthenticated={user.authorization && user.authorization.includes('rent')}
                />
                <ProtectedRoute
                    path="/panel/settings/general"
                    component={General}
                    isAuthenticated={user.authorization && user.authorization.includes('general')}
                />
                <ProtectedRoute
                  path="/panel/settings/tests"
                  component={Tests}
                  isAuthenticated={user.authorization && user.authorization.includes('general')}
                />
                <ProtectedRoute
                    path="/panel/settings/users"
                    component={Users}
                    isAuthenticated={user.authorization && user.authorization.includes('users')}
                />

                <Route render={() => <Redirect to="/panel/generator" />} />
            </div>
        </Dashboard>
    )
};

Worker.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Worker);
