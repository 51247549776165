import styled from 'styled-components'
import {
  background,
  border,
  color,
  flexbox,
  layout,
  opacity,
  position,
  size,
  space,
  grid,
} from 'styled-system'

const common = [background, space, flexbox, border, color, position, layout, size, opacity, grid]

export const Box = styled.div`
  ${common}
`

export const Row = styled.div`
  ${common};
  display: flex;
  flex-direction: row;
`
export const Grid = styled.div`
  ${common};
  display: grid;
  grid-template-columns: 1fr 1fr;
`
