import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import styled from 'styled-components'
import qs from 'querystring';

import Title from "../../../../../components/title";
import Button from "../../../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBuilding, faCheckCircle,
    faChevronLeft,
    faChevronRight, faDownload,
    faEllipsisH, faExclamationTriangle,
    faPlusCircle, faTrash,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import Input from "../../../../../components/input";
import LOGO_OTOMOTO from '../../../../../images/otomoto.png'
import {theme} from "../../../../../theme/theme";
import {db} from "../../../../../firebase/firebase";
import {verifyAuth} from "../../../../../redux/actions";


function Otomoto (props) {
    const {user,offers,dispatch} = props;
    
    const [loading,setLoading] = useState({
        login:false,
        offers:false,
        delete:false,
        update:false,
    });

    const [error,setError] = useState({
        login:false,
        offers:false,
        update:false,
        delete:false,
    });

    const [state,setState] = useState({
        username:'',
        password:'',
    });

    useEffect(() => {
        if(user.otomotoValid){
            setState({
                username:user.otomotoUsername,
                password:user.otomotoPassword,
            })
        }
    },[]);

    const updateLoading = (name,value) => {
        setLoading({
            ...loading,
            [name]:value
        })
    };

    const updateError = (name,value) => {
        setError({
            ...loading,
            [name]:value
        })
    };

    const loginToOtomoto = () => {
        updateLoading('login',true);

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        };

        axios.get(`https://us-central1-mylease-pl.cloudfunctions.net/app/loginotomoto?username=${state.username}&password=${state.password}`,config)
            .then(res => {
                updateLoading('login',false);
                dispatch(verifyAuth());
            })
            .catch(res => {
                updateLoading('login',false);
                updateError('login',true);
            });
    };

    const updateOffers = () => {
        updateLoading('update',true);

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        };

        axios.get(`https://us-central1-mylease-pl.cloudfunctions.net/app/updateoffers`,config)
            .then(res => {
                updateLoading('update',false);
                dispatch(verifyAuth());
            })
            .catch(res => {
                console.log(res.response);
                updateLoading('update',false);
                updateError('update',true);
            });
    };

    const deleteOffers = () => {
        updateLoading('delete',true);

        const config = {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        };

        axios.get(`https://us-central1-mylease-pl.cloudfunctions.net/app/deleteoffers`,config)
            .then(res => {
                updateLoading('delete',false);
                dispatch(verifyAuth());
            })
            .catch(res => {
                console.log(res);
                updateLoading('delete',false);
                updateError('delete',true);
            });
    };

    const logoutFromOtomoto = () => {
        updateLoading('login',true);
        db.collection('users')
            .doc(user.uid)
            .update({
                otomotoValid:false,
                otomotoRefreshToken:'',
            }).then(() => {
                dispatch(verifyAuth());
                updateLoading('login',false);
        });
    };

    return(
        <>
        <Wrapper>
            <ContentHeader>
                <Title>Integracja otomoto</Title>
            </ContentHeader>
            <ContentBody>
                <Block>
                    <BlockTitle>Dane logowania</BlockTitle>
                    <div style={{display:'grid',gridTemplateColumns:'150px 1fr',gridGap:'20px',width:'100%'}}>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img style={{width:'100%'}} src={LOGO_OTOMOTO}/>
                        </div>
                        <OtomotoInputs>
                            {
                                !user.otomotoValid ?
                                    <>
                                    {
                                        error.login &&
                                        <ErrorInfo>
                                            <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się zalogować
                                        </ErrorInfo>
                                    }
                                    <Input label="Nazwa użytkownika" value={state.username} onChange={(e) => setState({...state,username:e.target.value})}/>
                                        <Input type="password" label="Hasło" value={state.password} onChange={(e) => setState({...state,password:e.target.value})}/>
                                        <Button big onClick={loginToOtomoto}>
                                            {
                                                loading.login ?
                                                    'Loading ...' :
                                                    'Zaloguj się'
                                            }
                                        </Button>
                                    </> :
                                    <SuccessLogged>
                                        <h2><FontAwesomeIcon icon={faCheckCircle}/>Twoje konto jest aktywne</h2>
                                        <p>Pomyślnie aktywowaliśmy twoje konto w ramach <span>MyLease.pl Partner</span></p>
                                        <Button big onClick={logoutFromOtomoto}>
                                            {
                                                loading.login ?
                                                    'Loading ...' :
                                                    'Wyloguj się'
                                            }
                                        </Button>
                                    </SuccessLogged>
                            }
                        </OtomotoInputs>
                    </div>
                </Block>
                <Block>
                    <BlockTitle>Ogłoszenia</BlockTitle>
                    {
                        (error.update || error.delete) &&
                        <ErrorInfo>
                            <FontAwesomeIcon icon={faExclamationTriangle}/> Nie udało się wykonać operacji. Skontaktuj się z nami.
                        </ErrorInfo>
                    }
                    {
                        offers.length > 0 ?
                            <>
                            <Offers color={theme.green}>
                                <h2>{offers.filter(item => item.status === 'active').length}</h2>
                                <div>
                                    <h3>Aktywnych ogłoszeń</h3>
                                    <p>Ogłoszenia są dostępne na naszej stronie</p>
                                </div>
                            </Offers>
                            <Offers color={theme.yellow}>
                                <h2>{offers.filter(item => item.status === 'pending').length}</h2>
                                <div>
                                    <h3>Oczekujących ogłoszeń</h3>
                                    <p>Twoje ogłoszenia zostaną wkrótce aktywowane</p>
                                </div>
                            </Offers>
                            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',marginTop:'30px'}}>
                                <Button secondary color={theme.red} style={{marginRight:'15px'}} onClick={deleteOffers}>
                                    {
                                        loading.delete ?
                                            'Loading ...' :
                                            'Usuń wszystkie ogłoszenia'
                                    }
                                </Button>
                                <Button onClick={updateOffers}>
                                    {
                                        loading.update ?
                                            'Loading ...' :
                                            'Aktualizuj ogłoszenia'
                                    }
                                </Button>
                            </div>
                            </> :
                            <NoOffers>
                                <p>Brak ogłoszen</p>
                                {
                                    user.otomotoValid &&  <Button onClick={updateOffers}>
                                        {
                                            loading.update ?
                                                'Loading ...' :
                                                'Pobierz ogłoszenia'
                                        }
                                    </Button>
                                }
                            </NoOffers>

                    }
                </Block>
                <Block gcs={1} gce={3}>
                    <BlockTitle>Logi</BlockTitle>
                    <StyledTable>
                        {/*<thead>*/}
                        {/*    <tr>*/}
                        {/*        <th>Elo</th>*/}
                        {/*        <th>Elo2</th>*/}
                        {/*    </tr>*/}
                        {/*</thead>*/}
                        {/*<tbody>*/}
                        {/*    <tr>*/}
                        {/*        <td>Elo</td>*/}
                        {/*        <td>Elo2</td>*/}
                        {/*    </tr>*/}
                        {/*</tbody>*/}
                    </StyledTable>
                </Block>
            </ContentBody>
        </Wrapper>
        </>
    )
};

Otomoto.propTypes = {
};

function mapStateToProps(state) {
    return {
        user:state.auth.user,
        offers:state.offers
    };
}
export default connect(mapStateToProps)(Otomoto);


const ErrorInfo = styled.div`
  color: #cc0000;
  font-weight: 700;
  font-size: ${({theme}) => theme.font.XS}; 
  margin-bottom: 15px !important;
  
  svg{
    margin-right: 7px;
  }
`;

const StyledTable = styled.table`

`;

const NoOffers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-direction: column;
    width: 100%;
  p{
       font-weight: 700;
      color: ${({theme}) => theme.thirdColor};
  }
`;

const Offers = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;

  >h2{
    color: ${({color}) => color};
    font-size: 40px;
    margin: 0px;
    margin-right: 15px;
    font-weight: 800;
  }
  >div{
    h3{
      color: ${({color}) => color};
      font-size: 17px;
      font-weight: 700;
      margin: 0px;
    }
    p{
      margin: 0px;
      font-weight: 700;
      color: ${({theme}) => theme.thirdColor};
    }
  }
`;

const SuccessLogged = styled.div`
  h2{
  color: ${({theme}) => theme.green};
    svg{
      transform: scale(0.8);
      margin-right: 10px;
    }
  }
  p{
    font-weight: 700;
    color: ${({theme}) => theme.thirdColor};
  }
`;

const OtomotoInputs = styled.div`
  >div{
    margin-bottom: 10px;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  span{
    font-size: ${({theme}) => theme.font.S};
    font-weight: ${({theme}) => theme.font.Regular};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    
    &:hover{
      color: ${({theme}) => theme.primaryColor};
    }
    &.active{
      background: ${({theme}) => theme.primaryColor};
      color: white;
    }
  }
`;
const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  >div{
   margin-bottom: 10px;
  }
  >div:first-of-type{
    display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
        >div{
            margin-right: 10px;
         }
  }
  
`;

const StyledOption = styled.option`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: ${({theme}) => theme.font.M};
`;

const PatronSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${({theme}) => theme.fourthColor};
  border-radius: 3px;
  padding: 7px 25px;
  border: none;
`;

const ContentTable = styled.table`
 border-collapse:collapse;
  margin-top: 20px;
  width: 100%;
  tr{
    border-bottom: 1px solid ${({theme}) => theme.fourthColor};
    &:first-of-type, &:last-of-type{
       border-bottom: 0px;
    }
  }
  th,td{
    border: none;
    padding: 0px;
  }
  th{
    background: ${({theme}) => theme.fourthColor};
    font-size: ${({theme}) => theme.font.XS};
    font-weight: ${({theme}) => theme.font.Regular};
    color: ${({theme}) => theme.secondColor};
    padding: 6px 8px;
    text-align: left;
    &:first-of-type{
      border-radius: 3px 0 0 3px;
    }
     &:last-of-type{
      border-radius: 3px 0 0 3px;
    }
    &.textAlign-center{
      text-align: center;
    }
  }
  td{
    padding: 8px 8px;
    font-size: ${({theme}) => theme.font.XS};
    color: ${({theme}) => theme.thirdColor};
     font-weight: ${({theme}) => theme.font.Light};
     svg{
      margin: 0 auto;
      display: block;
     }
     &.name{
         color: ${({theme}) => theme.secondColor};
          min-width: 200px;
     }
     &.trash{
      cursor: pointer;
     }
     span{
      text-transform: uppercase;
         font-size: ${({theme}) => theme.font.XS};
         border-radius: 3px;
        padding: 3px 15px;
        font-weight: ${({theme}) => theme.font.Bold};
     }
    &.web{
      span{
        background:  ${({theme}) => theme.greenBackground};
        color: ${({theme}) => theme.green};
      }
    }
    
    &.comperia{
      span{
        background: ${({theme}) => theme.blueBackground};
        color: ${({theme}) => theme.blue};
      }
    }
    
    &.wlasny{
      span{
        background: ${({theme}) => theme.yellowBackground};
        color: ${({theme}) => theme.yellow};
      }
    }
    
    &.patron{
      span{
        height: 35px;
        width: 35px;
        margin: 0 auto;
        display: block;
        background: ${({theme}) => theme.fourthColor};
         font-weight: ${({theme}) => theme.font.Bold};
         font-size: ${({theme}) => theme.font.XS};
         display: flex;
         align-items: center;
         justify-content: center;
      }
    }
  }
`;

const BlockTitle = styled.h2`
    font-size: ${({theme}) => theme.font.M};
 
    font-weight: ${({theme}) => theme.font.Bold};
    color: ${({theme}) => theme.secondColor};
    margin: 0px;
    margin-bottom: 25px;
`;

const Block = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px;
  background: white;
  grid-column-start: ${({gcs}) => gcs || 'auto'};
  grid-column-end: ${({gce}) => gce || 'auto'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const ContentBody = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;
    grid-gap: 15px;
    margin-top: 30px;
`;

const Wrapper = styled.div`
  
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;