import {db, myFirebase} from "../../firebase/firebase";
import {loadOffersFunc} from "./offers";
import {loadWorkersFunc} from "./workers";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export const VERIFY_FAILURE = "VERIFY_FAILURE";

const requestLogin = () => {
    return {
        type: LOGIN_REQUEST
    };
};

const receiveLogin = (user) => {
    return {
        type: LOGIN_SUCCESS,
        user
    };
};

const loginError = () => {
    return {
        type: LOGIN_FAILURE
    };
};

const requestLogout = () => {
    return {
        type: LOGOUT_REQUEST
    };
};

const receiveLogout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

const logoutError = () => {
    return {
        type: LOGOUT_FAILURE
    };
};

const verifyRequest = () => {
    return {
        type: VERIFY_REQUEST
    };
};

const verifySuccess = () => {
    return {
        type: VERIFY_SUCCESS
    };
};

const verifyFailure = () => {
    return {
        type: VERIFY_FAILURE
    };
};

export const loginUser = (email, password) => dispatch => {
    dispatch(requestLogin());
    myFirebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(res => {
            dispatch(receiveLogin({
                ...res,
                ...res.data,
                token:res.xa,
            }));
        })
        .catch(error => {
            console.log(error);
            dispatch(loginError());
        });
};

export const logoutUser = () => dispatch => {
    dispatch(requestLogout());
    myFirebase
        .auth()
        .signOut()
        .then(() => {
            dispatch(receiveLogout());
        })
        .catch(error => {
            dispatch(logoutError());
        });
};

export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    myFirebase
        .auth()
        .onAuthStateChanged(res => {
            if (res !== null) {
                const workers = [];
                let user;
                db.collection('users')
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            if(doc.data().role === "worker"){
                                workers.push({
                                    ...doc.data()
                                });
                            }
                            if(doc.data().email === res.email){
                                user = doc.data()
                            }
                        });

                        // const user = workers.filter(item => item.email === res.email)[0];
                        dispatch(loadWorkersFunc(workers));
                        dispatch(receiveLogin({
                            ...res,
                            token:res.xa,
                            ...user
                        }));
                        console.log(3);
                        dispatch(loadOffersFunc());
                        dispatch(verifySuccess());
                    });
            }else {
                dispatch(verifyFailure())
            }
        });
};