import React, {useCallback, useState} from "react";
import styled from "styled-components"
import Block from "../../../../../../../components/Block";
import Input from "../../../../../../../components/input";
import Select from "../../../../../../../components/select";
import Title from "../../../../../../../components/title";
import Button from "../../../../../../../components/button";
import axios from "axios";
import {FINANCE_TYPES, MONTHS, YEARS} from "../../../../../../../theme/constans";

const OlxTests = () => {
  const [rate, setRate] = useState()
  const [state, setState] = useState({
    type: 'Leasing',
    year: 2024,
    price: 90000.00,
    period: 36,
    payment: 20,
    repurchase: 30,
  });

  const handleOnChange = useCallback((e) => {
    setRate(null)
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }, [state])

  const calculate = useCallback(async () => {
    setRate(null)
    const financeType = FINANCE_TYPES.find(ft => ft[0] === state.type)[1]
    const res = await axios.get(`https://us-central1-mylease-pl.cloudfunctions.net/rateOlx?type=${financeType}&year=${state.year}&price=${state.price}&period=${state.period}&payment=${state.payment}&repurchase=${state.repurchase}&key=eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjE2MDk3OTk5NjUsInN1YiI6Im1haWx`)
    setRate(`${res.data.price} ${res.data.type}`)
  }, [state])

  return (
    <Block title="Finansowanie OLX">
      <Grid>
        <Select marginTop="0"
                maxWidth="none" label="Finansowanie" options={['Leasing', 'Pożyczka', 'Kredyt']} name="type"
                value={state.type}
                onChange={handleOnChange}
        />
        <Select marginTop="0"
                maxWidth="none" label="Rok" options={YEARS} name="year"
                value={state.year}
                onChange={handleOnChange}
        />
        <Input type="double" marginTop="0" label="Cena brutto" name="price" value={state.price}
               onChange={handleOnChange}/>
        <Select marginTop="0"
                maxWidth="none" label="Okres" options={MONTHS} name="period"
                value={state.period}
                onChange={handleOnChange}
        />
        <Input type="int" marginTop="0" label="Wpłata w %" name="payment" value={state.payment}
               onChange={handleOnChange}/>
        {
          state.type === "Leasing" &&
          <Input type="int" marginTop="0" label="Wykup w %" name="repurchase" value={state.repurchase}
                 onChange={handleOnChange}/>
        }
        <Button big onClick={calculate}>
          Oblicz
        </Button>
        <Title third>{rate}</Title>
      </Grid>
    </Block>
  )
}

export default OlxTests

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
`;
