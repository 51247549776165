
import { LOAD_CONFIGURATION, SET_CONFIGURATION_PROPERTY } from "../actions";


export default (
    state = {
        wibor: 0,
    },
    action
) => {
    switch (action.type) {
        case LOAD_CONFIGURATION:
            return action.payload;
        case SET_CONFIGURATION_PROPERTY:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};
