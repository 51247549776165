import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import {Page, Text, View, Image, Document, StyleSheet, PDFDownloadLink, PDFViewer, Font} from '@react-pdf/renderer';
import Button from "../../../../../components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faGlobeEurope} from "@fortawesome/free-solid-svg-icons";
import LOGO from '../../../../../images/logo.png'
import OFFER_IMAGE from '../../../../../images/offerImage.png'
import SRWIS from '../../../../../images/car-repair.png'
import TIRES from '../../../../../images/wheel.png'
import INSURANCE from '../../../../../images/insurance.png'
import REPLACE_CAR from '../../../../../images/replace-car.png'
// import INSURANCEGREY from '../../../../../images/insuranceGrey.png'
import WEB from '../../../../../images/internet.png'
import FB from '../../../../../images/facebook.png'
import MONTSERRAT_LIGHT from '../../../../../assets/montserrat/Montserrat-SemiBold.ttf'
import MONTSERRAT_NORMAL from '../../../../../assets/montserrat/Montserrat-Bold.ttf'
import MONTSERRAT_BOLD from '../../../../../assets/montserrat/Montserrat-ExtraBold.ttf'
import {numberWithSpaces} from "../../../../../theme/theme";
import {getPricesInString} from "./functions";
import axios from "axios";
import offers from "../../../../../redux/reducers/offers";
import Spinner from "../../../../../components/spinner";
import Input from "../../../../../components/input";

import O1 from '../../../../../images/partner/1.png'
import O2 from '../../../../../images/partner/2.png'
import O3 from '../../../../../images/partner/3.png'
import O4 from '../../../../../images/partner/4.png'
import O5 from '../../../../../images/partner/5.png'
import O6 from '../../../../../images/partner/6.png'

const MyDocument = ({state,leasing,loan,rent,leasingAllPrice,loanAllPrice,offers}) => {
    let leasingInfo = getPricesInString(state.price,leasing.contribution.value,leasing.contribution.type,leasing.repurchase.value,leasing.repurchase.type);
    let loanInfo = getPricesInString(state.price,loan.contribution.value,loan.contribution.type,"0","%");

    return (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <View style={styles.header}>
                    <View>
                        <Image source={LOGO} style={{margin:20,width:150}}/>
                    </View>
                    <View style={styles.worker}>
                        <View style={{width:'200%',height:'400%',backgroundColor:'rgb(54,118,181)',position:'absolute',left:-8,bottom:-20,borderRadius:10,transform:'rotate(10deg)'}}></View>
                        {
                            state.user.email === "mylease@mylease.pl" ?
                              <>
                                  <Text style={styles.promoWorkerText}>OFERTA</Text>
                                  <Text style={styles.promoWorkerText}>PROMOCYJNA</Text>
                              </> :
                              <>
                                  <Text style={styles.workerLabel}>Opiekun</Text>
                                  <Text style={styles.workerText}>{state.user.name}</Text>
                                  <Text style={styles.workerText}>{state.user.phone}</Text>
                                  <Text style={styles.workerText}>{state.user.email}</Text>
                              </>
                        }
                    </View>
                </View>
                <View style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <View style={[styles.car,{width:'50%'}]}>
                        <View style={[styles.carRow,{flexDirection:'column', alignItems:'flex-start',marginBottom:20}]}>
                            <Text style={[styles.carLabel]}>KLIENT:</Text>
                            <Text style={styles.carText}>{state.client}</Text>
                        </View>
                        <View style={styles.carInfo}>
                            <Text style={styles.label}>{state.typeName.toUpperCase()}:</Text>
                            <Text style={styles.brandText}>{state.brand} {state.model}</Text>
                            <Text style={styles.versionText}>{state.version}</Text>
                        </View>
                        {
                            state.typeName === 'Samochód' &&
                            <View style={styles.carRow}>
                                <Text style={styles.carLabel}>NADWOZIE:</Text>
                                <Text style={styles.carText}>{state.bodyType}</Text>
                            </View>
                        }
                        {
                            (state.typeName === 'Samochód' || state.typeName === 'Motocykl') &&
                            <View style={styles.carRow}>
                                <Text style={styles.carLabel}>MOC:</Text>
                                <Text style={styles.carText}>{state.power} KM</Text>
                            </View>
                        }
                        <View style={styles.carRow}>
                            <Text style={styles.carLabel}>ROK:</Text>
                            <Text style={styles.carText}>{state.year}</Text>
                        </View>
                        {
                            state.financeType !== 'Wynajem' &&
                            <View style={styles.carRow}>
                                <Text style={styles.carLabel}>CENA {state.financeType === 'Pożyczka' ? 'BRUTTO' : 'NETTO'}:</Text>
                                <Text style={styles.carText}>{numberWithSpaces(state.catalogPrice.toString().replaceAll(".", ","))} PLN</Text>
                            </View>
                        }
                    </View>
                    <View style={{width:'50%',position:'relative',paddingTop:30}}>
                        <Image source={OFFER_IMAGE} style={{height:'200px',objectFit:'contain'}}/>
                    </View>
                </View>

                {
                    state.financeType === 'Leasing' &&
                        <>
                        <View style={{...styles.primaryFinance, position: 'relative', flexDirection: 'row-reverse'}}>
                            <View style={{
                                position: 'absolute',
                                left: '-100%',
                                width: '100%',
                                top: 0,
                                height: '100%',
                                backgroundColor: '#d9d9d9'
                            }}></View>
                            <View style={{
                                padding: 10,
                                justifyContent: 'center',
                                backgroundColor: 'rgb(54,118,181)',
                                borderBottomRightRadius: 8,
                                borderTopRightRadius: 8,
                                width: '25%'
                            }}>
                                <Text style={styles.priceText}>{leasing.rate}</Text>
                                <Text style={styles.priceSubText}>netto / mies.</Text>
                            </View>
                            <View style={{
                                flexDirection: 'column',
                                padding: 10,
                                paddingRight: 20,
                                paddingBottom: 15,
                                overflow: 'hidden',
                                paddingLeft: 0,
                                backgroundColor: '#d9d9d9',
                                width: '75%'
                            }}>
                                <View>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{width: '40%', marginRight: '2%'}}>
                                            <Text style={styles.financeLabel}>Radzaj umowy</Text>
                                            <View style={styles.radioWrapper}>
                                                <View
                                                    style={[styles.radio,styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText, state.financeType === 'Leasing' ? {color: 'white'} : '']}>{state.financeType}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '58%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Okres umowy</Text>
                                                <Text style={styles.financeLabel}></Text>
                                            </View>
                                            <View style={styles.radioWrapper}>
                                                <View style={[styles.radio, styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText, {color: 'white'}]}>{leasing.period} miesięcy</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row', marginTop: 10}}>
                                        <View style={{width: '49%', marginRight: '2%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Wpłata</Text>
                                                <Text
                                                    style={styles.financeLabel}>{numberWithSpaces(leasingInfo[0][0]).replaceAll(".", ",")} PLN {state.catalogPrice === state.price ? `(${leasingInfo[0][1]}%)` : ''}</Text>
                                            </View>
                                            <View style={styles.sliderWrapper}>
                                                <View
                                                    style={[styles.slider, {width: `${!isNaN(leasingInfo[0][2]) ? leasingInfo[0][2] : 0}%`}]}>
                                                    <View style={styles.sliderDrag}/>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '49%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Wykup</Text>
                                                <Text
                                                    style={styles.financeLabel}>{numberWithSpaces(leasingInfo[1][0]).replaceAll(".", ",")} PLN {state.catalogPrice === state.price ? `(${leasingInfo[1][1]}%)` : ''}</Text>
                                            </View>
                                            <View style={styles.sliderWrapper}>
                                                <View
                                                    style={[styles.slider, {width: `${!isNaN(leasingInfo[1][2]) ? leasingInfo[1][2] : 0 }%`}]}>
                                                    <View style={styles.sliderDrag}/>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',alignItems:'space-between'}}>
                            <View style={{padding: 20, paddingVertical: 10, paddingBottom: 0,flex:'1 1'}}>
                                {
                                    leasing.allCosts &&
                                    <Text
                                        style={styles.allCosts}>{`Koszt całkowity: ${numberWithSpaces(leasingAllPrice()[0]).replaceAll(".", ",")} PLN (${leasingAllPrice()[1]}%)`}</Text>
                                }
                            </View>
                            <View style={{padding:20,paddingVertical:10,paddingBottom:0}}>
                                <Text style={styles.allCosts}>{`Rata: ${(Math.round((parseFloat(leasing.rate.replaceAll(',','.'))*1.23) * 100)/100).toString().replaceAll('.',',')} PLN brutto / mies.`}</Text>
                            </View>
                        </View>
                    </>
                }
                {
                    state.financeType === 'Pożyczka' &&
                    <>
                        <View style={{...styles.primaryFinance, position: 'relative', flexDirection: 'row-reverse'}}>
                            <View style={{
                                position: 'absolute',
                                left: '-100%',
                                width: '100%',
                                top: 0,
                                height: '100%',
                                backgroundColor: '#d9d9d9'
                            }}></View>
                            <View style={{
                                padding: 10,
                                justifyContent: 'center',
                                backgroundColor: 'rgb(54,118,181)',
                                borderBottomRightRadius: 8,
                                borderTopRightRadius: 8,
                                width: '25%'
                            }}>
                                <Text style={styles.priceText}>{loan.rate}</Text>
                                <Text style={styles.priceSubText}>PLN / mies.</Text>
                            </View>
                            <View style={{
                                flexDirection: 'column',
                                padding: 10,
                                paddingRight: 20,
                                paddingBottom: 15,
                                overflow: 'hidden',
                                paddingLeft: 0,
                                backgroundColor: '#d9d9d9',
                                width: '75%'
                            }}>
                                <View>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{width: '40%', marginRight: '2%'}}>
                                            <Text style={styles.financeLabel}>Rodzaj umowy</Text>
                                            <View style={styles.radioWrapper}>
                                                <View
                                                    style={[styles.radio,styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText,{color: 'white'}]}>{state.financeType}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '58%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Okres umowy</Text>
                                                <Text style={styles.financeLabel}></Text>
                                            </View>
                                            <View style={styles.radioWrapper}>
                                                <View style={[styles.radio, styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText, {color: 'white'}]}>{loan.period} miesięcy</Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row', marginTop: 10}}>
                                        <View style={{width: '49%', marginRight: '2%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Wpłata</Text>
                                                <Text
                                                    style={styles.financeLabel}>{numberWithSpaces(loanInfo[0][0]).replaceAll(".", ",")} PLN ({loanInfo[0][1]}%)</Text>
                                            </View>
                                            <View style={styles.sliderWrapper}>
                                                <View
                                                    style={[styles.slider, {width: `${!isNaN(loanInfo[0][2]) ? loanInfo[0][2] : 0}%`}]}>
                                                    <View style={styles.sliderDrag}/>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '49%'}}>

                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',alignItems:'space-between'}}>
                            <View style={{padding: 20, paddingVertical: 10, paddingBottom: 0,flex:'1 1'}}>
                                {
                                    loan.allCosts &&
                                    <Text
                                        style={styles.allCosts}>{`Koszt całkowity: ${numberWithSpaces(loanAllPrice()[0].toString().replaceAll(".", ","))} PLN (${loanAllPrice()[1]}%)`}</Text>
                                }
                            </View>
                        </View>
                    </>
                }
                {
                    state.financeType === 'Wynajem' &&
                    <>
                        <View style={{...styles.primaryFinance, position: 'relative', flexDirection: 'row-reverse'}}>
                            <View style={{
                                position: 'absolute',
                                left: '-100%',
                                width: '100%',
                                top: 0,
                                height: '100%',
                                backgroundColor: '#d9d9d9'
                            }}></View>
                            <View style={{
                                padding: 10,
                                justifyContent: 'center',
                                backgroundColor: 'rgb(54,118,181)',
                                borderBottomRightRadius: 8,
                                borderTopRightRadius: 8,
                                width: '25%'
                            }}>
                                <Text style={styles.priceText}>{rent.rate}</Text>
                                <Text style={styles.priceSubText}>netto / mies.</Text>
                            </View>
                            <View style={{
                                flexDirection: 'column',
                                padding: 20,
                                paddingRight: 20,
                                paddingBottom: 15,
                                overflow: 'hidden',
                                paddingLeft: 0,
                                backgroundColor: '#d9d9d9',
                                width: '75%'
                            }}>
                                <View>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{width: '25%', marginRight: '3%'}}>
                                            <Text style={styles.financeLabel}>Radzaj umowy</Text>
                                            <View style={styles.radioWrapper}>
                                                <View
                                                    style={[styles.radio,styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText,{color: 'white'}]}>{state.financeType}</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '30%', marginRight: '3%'}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Okres umowy</Text>
                                                <Text style={styles.financeLabel}></Text>
                                            </View>
                                            <View style={styles.radioWrapper}>
                                                <View style={[styles.radio, styles.radioActive]}>
                                                    <Text
                                                        style={[styles.radioText, {color: 'white'}]}>{rent.period} miesięcy</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{width: '38%',marginTop:1}}>
                                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                                <Text style={styles.financeLabel}>Wpłata</Text>
                                                <Text
                                                    style={styles.financeLabel}>{rent.contribution.value} {rent.contribution.type}</Text>
                                            </View>
                                            <View style={styles.sliderWrapper}>
                                                <View
                                                    style={[styles.slider, {width: `${33}%`}]}>
                                                    <View style={styles.sliderDrag}/>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{flexDirection: 'row', marginTop: 10}}>

                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{flexDirection:'row',alignItems:'space-between'}}>
                            <View style={{padding: 20, paddingVertical: 10, paddingBottom: 0,flex:'1 1'}}>
                                <div style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <Text style={styles.financeLabel}>Umowa zawiera</Text>
                                    <Text style={styles.financeLabel}>Roczny limit: {numberWithSpaces(rent.limit)} KM</Text>
                                </div>
                                <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                                    {
                                        rent.serwis &&
                                        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginRight:15}}>
                                            <Image source={SRWIS} style={{margin:10,height:20}}/>
                                            <Text style={styles.financeLabel}>Serwis</Text>
                                        </View>
                                    }
                                    {
                                        rent.wheels &&
                                        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginRight:15}}>
                                            <Image source={TIRES} style={{margin:10,height:20}}/>
                                            <Text style={styles.financeLabel}>Opony</Text>
                                        </View>
                                    }
                                    {
                                        rent.replaceCar &&
                                        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginRight:15}}>
                                            <Image source={REPLACE_CAR} style={{margin:10,height:20}}/>
                                            <Text style={styles.financeLabel}>Auto zastępcze</Text>
                                        </View>
                                    }
                                    {
                                        rent.carInsurance &&
                                        <View style={{flexDirection:'row',alignItems:'center',justifyContent:'flex-start',marginRight:15}}>
                                            {/*{*/}
                                            {/*    rent.carInsuranceValue !== '' ?*/}
                                            {/*        <Image source={INSURANCEGREY} style={[{margin:10,height:20}]}/> :*/}
                                            {/*        <Image source={INSURANCE} style={[{margin:10,height:20}]}/>*/}
                                            {/*}*/}
                                            <Image source={INSURANCE} style={[{margin:10,height:20}]}/>
                                            <Text style={styles.financeLabel}>Ubezpieczenie {rent.carInsuranceValue !== '' ? `(${rent.carInsuranceValue} PLN)`:''}</Text>
                                        </View>
                                    }

                                </View>
                            </View>
                        </View>
                    </>
                }
                {
                    (leasing.otherOptions.length > 0 || loan.otherOptions.length > 0 || rent.otherOptions.length > 0) &&
                    <View style={{padding:20}}>
                        <View style={{marginBottom:10}}>
                            <Text style={styles.label}>INNE OPCJE FINANSOWANIA</Text>
                        </View>
                        <View style={{flexDirection:'row'}}>
                            {
                                state.otherFinanceOption === 'Leasing'  &&
                                <View style={{flex:1,maxWidth:'60%',paddingRight:10}}>
                                    <View style={styles.tableHeader}>
                                        <Text style={styles.tableTextHeader}>Leasing</Text>
                                    </View>
                                    <View style={styles.tableColumns}>
                                        <View style={{width:'16%'}}>
                                            <Text style={styles.column}>Okres</Text>
                                        </View>
                                        <View style={{width:'28%'}}>
                                            <Text style={styles.column}>Wpłata</Text>
                                        </View>
                                        <View style={{width:'28%'}}>
                                            <Text style={styles.column}>Wykup</Text>
                                        </View>
                                        <View style={{width:'28%'}}>
                                            <Text style={styles.column}>Rata (netto)</Text>
                                        </View>
                                    </View>
                                    {
                                        leasing.otherOptions.map(item => {
                                            const values = getPricesInString(state.price,item.contribution.value,item.contribution.type,item.repurchase.value,item.repurchase.type);
                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={{width:'16%'}}>
                                                        <Text style={styles.row}>{item.period} mies.</Text>
                                                    </View>
                                                    <View style={{width:'28%'}}>
                                                        <Text style={styles.row}>{values[0][0]} PLN {state.catalogPrice === state.price ? `(${values[0][1]}%)` : ''}</Text>
                                                    </View>
                                                    <View style={{width:'28%'}}>
                                                        <Text style={styles.row}>{values[1][0]} PLN {state.catalogPrice === state.price ? `(${values[1][1]}%)` : ''}</Text>
                                                    </View>
                                                    <View style={{width:'28%'}}>
                                                        <Text style={styles.row}>{item.rate} PLN</Text>
                                                    </View>
                                                </View>
                                            )
                                        }

                                        )
                                    }

                                </View>
                            }
                            {
                                state.otherFinanceOption === 'Wynajem' &&
                                <View style={{flex: 1, maxWidth: '70%', paddingRight: 10}}>
                                    <View style={styles.tableHeader}>
                                        <Text style={styles.tableTextHeader}>Wynajem</Text>
                                    </View>
                                    <View style={styles.tableColumns}>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Okres</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Wpłata</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Limit</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Umowa</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Rata</Text>
                                        </View>
                                    </View>
                                    {
                                        rent.otherOptions.map(item =>
                                            <View style={styles.tableRow}>
                                                <View style={{flex: 1}}>
                                                    <Text style={styles.row}>{item.period} mies.</Text>
                                                </View>
                                                <View style={{flex: 1}}>
                                                    <Text style={styles.row}>{item.contribution.value}{item.contribution.type === '%' ? '%' : ' PLN'}</Text>
                                                </View>
                                                <View style={{flex: 1}}>
                                                    <Text style={styles.row}>{numberWithSpaces(item.limit)} KM</Text>
                                                </View>
                                                <View style={{flex: 1,flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                                   {
                                                       item.serwis &&
                                                       <Image source={SRWIS} style={{margin:'0 5px',height:10,width:10}}/>
                                                   }
                                                    {
                                                        item.wheels &&
                                                        <Image source={TIRES} style={{margin:'0 5px',height:10,width:10}}/>
                                                    }
                                                    {
                                                        item.carInsurance &&
                                                        <Image source={INSURANCE} style={{margin:'0 5px',height:10,width:10}}/>
                                                    }
                                                    {
                                                        item.replaceCar &&
                                                        <Image source={REPLACE_CAR} style={{margin:'0 5px',height:10,width:10}}/>
                                                    }
                                                </View>
                                                <View style={{flex: 1}}>
                                                    <Text style={styles.row}>{item.rate} PLN</Text>
                                                </View>
                                            </View>
                                        )
                                    }
                                </View>
                            }
                            {
                                state.otherFinanceOption === 'Pożyczka' &&
                                <View style={{flex: 1, maxWidth: '50%', paddingRight: 10}}>
                                    <View style={styles.tableHeader}>
                                        <Text style={styles.tableTextHeader}>Pożyczka</Text>
                                    </View>
                                    <View style={styles.tableColumns}>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Okres</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Wpłata</Text>
                                        </View>
                                        <View style={{flex: 1}}>
                                            <Text style={styles.column}>Rata</Text>
                                        </View>
                                    </View>
                                    {
                                        loan.otherOptions.map(item => {
                                            const values = getPricesInString(state.price,item.contribution.value,item.contribution.type,"0","%");

                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={{flex: 1}}>
                                                        <Text style={styles.row}>{item.period} mies.</Text>
                                                    </View>
                                                    <View style={{flex: 1}}>
                                                        <Text style={styles.row}>{values[0][0]} PLN ({values[0][1]}%)</Text>
                                                    </View>
                                                    <View style={{flex: 1}}>
                                                        <Text style={styles.row}>{item.rate} PLN</Text>
                                                    </View>
                                                </View>
                                            )
                                        }
                                        )
                                    }
                                </View>
                            }
                        </View>
                    </View>
                }
            </View>
            <View>
                <View style={styles.footer}>
                    <View>
                        <Text style={styles.footerText}>MyLease.pl</Text>
                        <Text style={styles.footerText}>ul. Sowińskiego 4, 40-018 Katowice</Text>
                    </View>
                    <View>
                        <Text style={styles.footerText}><FontAwesomeIcon icon={faGlobeEurope}/>www.mylease.pl</Text>
                    </View>
                </View>
                <View style={styles.footer2}>
                    <Text style={styles.footer2Text}>Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłacznie informacyjny. Ostateczne warunki umowy zaleza od oceny zdolnosci leasingowej
                        Leasingobiorcy oraz finansowanego przedmiotu i zostana uregulowane w umowie leasingu.</Text>
                </View>
            </View>
        </Page>
        {
            state.additionalOffers &&
            <Page size="A4" style={[styles.page,styles.page2]}>
                <View style={styles.header2}>
                    <View>
                        <Image source={LOGO} style={{margin:20,width:150}}/>
                    </View>
                </View>
                <View style={{flex: '1 1'}}>
                    <View style={{flex: '1 1',padding:'0px 20px 13px 20px'}}>
                        <View style={{flexDirection:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
                            {/*{*/}
                            {/*    offers.map(offer =>*/}
                            {/*        <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>*/}
                            {/*            <Image source={`https://thingproxy.freeboard.io/fetch/${offer.image}`} style={{objectFit:'contain'}}/>*/}
                            {/*            /!*<Image source={offer.image} style={{objectFit:'contain'}}/>*!/*/}
                            {/*            /!*<Image source={`${offer.image}`} style={{objectFit:'contain'}}/>*!/*/}
                            {/*        </View>*/}
                            {/*    )*/}
                            {/*}*/}
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O1} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O2} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O3} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O4} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O5} style={{objectFit:'contain'}}/>
                            </View>
                            <View style={{width:'273px',height:'207px',marginBottom:'10px'}}>
                                <Image source={O6} style={{objectFit:'contain'}}/>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={styles.footer}>
                        <View>
                            <Text style={styles.footerText}>MyLease.pl</Text>
                            <Text style={styles.footerText}>ul. Sowińskiego 4, 40-018 Katowice</Text>
                        </View>
                        <View>
                            <Text style={styles.footerText}><FontAwesomeIcon icon={faGlobeEurope}/>www.mylease.pl</Text>
                        </View>
                    </View>
                    <View style={styles.footer2}>
                        <Text style={styles.footer2Text}>Niniejszy materiał nie stanowi oferty w rozumieniu art. 66 K.C. i ma charakter wyłacznie informacyjny. Ostateczne warunki umowy zaleza od oceny zdolnosci leasingowej
                            Leasingobiorcy oraz finansowanego przedmiotu i zostana uregulowane w umowie leasingu.</Text>
                    </View>
                </View>
            </Page>
        }
    </Document>
)};


Font.register({
    family: 'MontserratLight',
    src:MONTSERRAT_LIGHT,
});
Font.register({
    family: 'Montserrat',
    src:MONTSERRAT_NORMAL,
});
Font.register({
    family: 'MontserratBold',
    src:MONTSERRAT_BOLD,
});
function PDFGenerator ({state,leasing,loan,rent,leasingAllPrice,loanAllPrice}) {
    // const [offers,setOffers] = useState([]);
    //
    // useEffect(() => {
    //     axios.get('https://thingproxy.freeboard.io/fetch/https://admin.myfleet.force4.pl/wp-json/rest/v1/partner')
    //         .then((res) => {
    //             setOffers(res.data);
    //         })
    // },[]);

    return(
        <>
            {/*<PDFViewer style={{width:'100%',height:500}}>*/}
            {/*    <MyDocument state={state} leasing={leasing} loan={loan} rent={rent} leasingAllPrice={leasingAllPrice} loanAllPrice={loanAllPrice}/>*/}
            {/*</PDFViewer>*/}
            <PDFDownloadLink document={<MyDocument state={state} leasing={leasing} loan={loan} rent={rent} leasingAllPrice={leasingAllPrice} loanAllPrice={loanAllPrice} offers={offers}/>} fileName="oferta.pdf">
                <Button big>
                    <FontAwesomeIcon icon={faDownload}/> Pobierz ofertę
                </Button>
                {/*{*/}
                {/*    offers.length > 0 ?*/}
                {/*        <Button big>*/}
                {/*            <FontAwesomeIcon icon={faDownload}/> Pobierz ofertę*/}
                {/*        </Button> :*/}
                {/*        <Spinner/>*/}
                {/*}*/}
            </PDFDownloadLink>
        </>
    )
};

const styles = StyleSheet.create({
    page2: {
        height:'100%',
        flexDirection:'column',
        justifyContent:'space-between',
        alignItems:'center'
    },
    allCosts: {
        fontFamily: 'Montserrat',
        fontSize:11,
        color:'#27313f'
    },
    tableTextHeader: {
        fontFamily: 'Montserrat',
        fontSize:12,
        color:'white',
        marginBottom:2,
        textAlign:'center'
    },
    row: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'#27313f',
        textAlign:'center',
    },
    column: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'#27313f',
        textAlign:'center',
    },
    tableRow: {
        flexDirection:'row',
        justifyContent:'center',
        paddingVertical:5,
        borderBottom:'1px solid #27313f'
    },
    tableColumns: {
        flexDirection:'row',
        justifyContent:'center',
        backgroundColor:'#d9d9d9',
        paddingVertical:2,
    },
    tableHeader: {
        padding:3,
        backgroundColor: 'rgb(54,118,181)',
        borderRadius:3
    },
    footer2Text: {
        fontFamily: 'MontserratLight',
        fontSize:7,
        color:'white',
        marginBottom:2,
        textAlign:'center'
    },
    footerText: {
        fontFamily: 'Montserrat',
        fontSize:9,
        color:'white',
        marginBottom:2
    },
    footer2: {
        backgroundColor:'rgb(42,49,60)',
        padding:10,
        justifyContent:'center',
        alignItems: 'center',
        flexDirection:'row'
    },
    footer: {
        backgroundColor:'rgb(42,49,60)',
        padding:15,
        paddingBottom:10,
        justifyContent:'space-between',
        alignItems: 'center',
        flexDirection:'row'
    },
    priceSubText: {
        fontFamily: 'Montserrat',
        fontSize:15,
        color:'white',
        textAlign:'center'
    },
    priceTextBrutto: {
        fontFamily: 'Montserrat',
        fontSize:18,
        color:'white',
        textAlign:'center'
    },
    priceText: {
        fontFamily: 'Montserrat',
        fontSize:30,
        color:'white',
        textAlign:'center'
    },
    radioText: {
        fontFamily: 'Montserrat',
        fontSize:10,
        color:'#27313f',
        textAlign:'center'
    },
    radioActive: {
        backgroundColor: 'rgb(54,118,181)'
    },
    sliderDrag: {
        width: 20,
        height: 20,
        backgroundColor:'white',
        position:'absolute',
        right:-10,
        top:0,
        borderRadius:'50%'
    },
    slider: {
        width:'50%',
        height:20,
        backgroundColor: 'rgb(54,118,181)',
        borderRadius:4,
        position:'relative',
    },
    radio: {
        flex:1,
        padding: 3,
        borderRadius: 3
    },
    sliderWrapper: {
        backgroundColor:'#bfbfbf',
        flexDirection:'row',
        marginTop:5,
        padding:0,
        borderRadius:3,
        position: 'relative',
    },
    radioWrapper: {
        backgroundColor:'white',
        flexDirection:'row',
        marginTop:5,
        padding:2,
        borderRadius:3
    },
    financeLabel: {
        fontFamily: 'Montserrat',
        fontSize:11,
        color:'#27313f'
    },
    primaryFinance: {
       flexDirection:'row',
        marginHorizontal: 20,
        marginTop:40
    },
    carRow: {
       display:'flex',
        flexDirection:'row',
        alignItems:'flex-end',
        marginTop: 0,
    },
    carLabel: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'#27313f',
        margin:0,
        marginRight: 7
    },
    carText: {
        fontFamily: 'MontserratBold',
        fontSize:15,
        color:'#27313f',
        margin:0,
    },
    versionText: {
        fontFamily: 'Montserrat',
        fontSize:15,
        color:'rgb(100,100,100)',
        margin:0,
        marginBottom: 15
    },
    brandText: {
        fontFamily: 'MontserratBold',
        fontSize:28,
        color:'#27313f',
        margin:0
    },
    page2Title: {
        fontFamily: 'Montserrat',
        fontSize:18,
        textAlign:'center',
        color:'#27313f'
    },
    label: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'#27313f'
    },
    carInfo: {
        display:'flex',
    },
    car: {
        display:'flex',
        paddingLeft:20,
        marginTop:50
    },
    workerText: {
        fontFamily: 'Montserrat',
        fontSize:13,
        color:'white'
    },
    promoWorkerText: {
        fontFamily: 'Montserrat',
        fontSize:20,
        color:'white'
    },
    workerLabel: {
        fontFamily: 'Montserrat',
        fontSize:10,
        color:'white',
        marginBottom:5
    },
    worker: {
        padding:20,
        marginRight:20,
        position:'relative'
    },
    page: {
        flexDirection: 'column',
        justifyContent: 'space-between'
        // backgroundColor: '#E4E4E4'
    },
    header2: {
        flexDirection: 'row',
        justifyContent:'space-between',
    },
    header: {
        flexDirection: 'row',
        justifyContent:'space-between',
        flexGrow: 1
    }
});

PDFGenerator.propTypes = {
};

export default PDFGenerator;
