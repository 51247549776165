import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from "styled-components";

const DatePicker = ({label, value, onChange, type = "date", error}) => {

    return (
        <form noValidate>
            <TextField
                id="date"
                label={label}
                type={type}
                defaultValue={value}
                value={value}
                onChange={onChange}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {error && <StyledP>{error}</StyledP>}
        </form>
    );
}

export default DatePicker;

const StyledP = styled.p`
  font-size: 12px;
  margin: 0px;
  margin-top: 5px;
  color: darkred;
  `;