import React, { useCallback } from "react";
import styled from "styled-components"
import Block from "../../../../../../../components/Block";
import Title from "../../../../../../../components/title";
import Input from "../../../../../../../components/input";
import update from 'immutability-helper';

const CommissionBlock = ({state, setState}) => {

  const handleOnChange = useCallback((e, type, row) => {
    const tmp = update(state, {
      commission: {
        [type]: {
          [row]: {
            $set: e.target.value
          }
        }
      }
    })

    setState(tmp)
  }, [state])

  return (
    <StyledBlock title="Aktualne prowizje">
      <Grid columns={5}>
        <Title third></Title>
        <Title third>{`< 20 tyś.`}</Title>
        <Title third>{`< 30 tyś.`}</Title>
        <Title third>{`< 40 tyś.`}</Title>
        <Title third>{`> 40 tyś.`}</Title>
      </Grid>
      <>
        <Grid columns={5}>
          <Title third>Kalkulator</Title>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.calculator['20k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'calculator', '20k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.calculator['30k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'calculator', '30k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.calculator['40k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'calculator', '40k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.calculator['more'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'calculator', 'more')}/>
        </Grid>
        <Grid columns={5}>
          <Title third>Oferty</Title>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.offers['20k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'offers', '20k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.offers['30k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'offers', '30k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.offers['40k'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'offers', '40k')}/>
          <Input type="double" marginTop="0" label="" name="wibor"
                 value={state.commission.offers['more'] ?? "0.00"}
                 onChange={(e) => handleOnChange(e, 'offers', 'more')}/>
        </Grid>
      </>
    </StyledBlock>
  )
}

export default CommissionBlock

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${({columns}) => `repeat(${columns}, 1fr)`};
    grid-gap: 10px;
`;

const StyledBlock = styled(Block)`
    grid-column-start: 1;
    grid-column-end: 4;
`
