import axios from "axios";
import React from "react";
import Button from "../../../../../../../components/button";
import styled from "styled-components"
import Block from "../../../../../../../components/Block";

const DeploySite = () => {

  const handleDeploy = () => {
    axios.get(`https://hook.eu1.make.com/nvosgho5rmhtqutfct8b2qrt4b35k0gy`)
      .then(res => {
        window.alert("SUKCES! Przebudowa strony została rozpoczęte pomyślnie - może to potrwać max. 30 minut.")
      })
      .catch(res => {
        window.alert("ERROR! Nie udało się rozpocząć przebudowy strony.")
      });
  }

  return (
    <StyledBlock title="Przebudowa strony mylease.pl">
      <Button onClick={handleDeploy}>
        Odśwież wersję strony
      </Button>
    </StyledBlock>
  )
}

export default DeploySite

const StyledBlock = styled(Block)`
  grid-column-start: 1;
  grid-column-end: 4;
`
