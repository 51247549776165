import React, {useCallback} from "react";
import styled from "styled-components"
import Block from "../../../../../../../components/Block";
import Title from "../../../../../../../components/title";
import Input from "../../../../../../../components/input";
import update from 'immutability-helper';
import {YEARS, FINANCE_TYPES} from "../../../../../../../theme/constans";

const MarginsBlock = ({state, setState}) => {

  const handleOnChange = useCallback((e, type, year) => {
    const tmp = update(state, {
      margin: {
        [type]: {
          [year]: {
            $set: e.target.value
          }
        }
      }
    })

    setState(tmp)
  }, [state])

  return (
    <StyledBlock title="Aktualne marże">
      <Grid columns={YEARS.length + 1}>
        <Title third></Title>
        {
          YEARS.map(year =>
            <Title third>{year}</Title>
          )
        }
      </Grid>
      <>
        {
          FINANCE_TYPES.map(ft =>
            <Grid columns={YEARS.length + 1}>
              <Title third>{ft[0]}</Title>
              {
                YEARS.map(year =>
                  <Input type="double" marginTop="0" label="" name="wibor"
                         value={state.margin[ft[1]] ? state.margin[ft[1]][year] ?? "0.00" : "0.00"}
                         onChange={(e) => handleOnChange(e, ft[1], year)}/>
                )
              }
            </Grid>
          )
        }
      </>
    </StyledBlock>
  )
}

export default MarginsBlock

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({columns}) => `repeat(${columns}, 1fr)`};
  grid-gap: 10px;
`;

const StyledBlock = styled(Block)`
  grid-column-start: 1;
  grid-column-end: 4;
`