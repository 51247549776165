
import {LOAD_WORKERS} from "../actions/workers";


export default (
    state = [],
    action
) => {
    switch (action.type) {
        case LOAD_WORKERS:
            return action.payload;
        default:
            return state;
    }
};