export const API_URL = "https://us-central1-mylease-pl.cloudfunctions.net/app";

export const WIBOR = 2.56;
export const YEARS = [2024,2023,2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010]
export const MONTHS = [24,30,35,36,47,48,59,60,72,84];

export const FINANCE_TYPES = [
  ['Leasing', 'leasing'],
  ['Leasing+', 'leasingPlus'],
  ['Pożyczka', 'pozyczka'],
  ['Pożyczka+', 'pozyczkaPlus'],
  ['Kredyt', 'kredyt'],
]
