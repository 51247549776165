String.prototype.replaceAll = function(str1, str2, ignore)
{
    return this.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(str2)=="string")?str2.replace(/\$/g,"$$$$"):str2);
};

export function getRate(cenaNettoParam,okresParam,wplataParam,wplataTypeParam,wykupParam,wykupTypeParam,marzaParam,prowizjaParam, prowizjaPartner, wibor){
    let marza = parseFloat(marzaParam.toString().replaceAll(',','.'));
    let prowizja = parseFloat(prowizjaParam.replaceAll(',','.')) + prowizjaPartner;
    let cenaNetto = parseFloat(cenaNettoParam.replaceAll(',','.'));
    let okres = parseInt(okresParam);
    let wplata;
    let wykup;
    let type = 0;
    if(wplataTypeParam === '%'){
        wplata = cenaNetto*(parseFloat(wplataParam.replaceAll(',','.'))/100);
    }else {
        wplata = parseFloat(wplataParam.replaceAll(',','.'));
    }

    if(wykupTypeParam === '%'){
        wykup = cenaNetto*(parseFloat(wykupParam.replaceAll(',','.'))/100);
    }else {
        wykup = parseFloat(wykupParam.replaceAll(',','.'));
    }

    if(prowizja > 4){
        marza += (prowizja-4)/2
    }
    let rate_per_period = ((marza + wibor) / 100)/12;
    let number_of_payments = okres;
    let present_value = -(cenaNetto - wplata + (cenaNetto * (prowizja/100)));
    let future_value = wykup;


    future_value = typeof future_value !== 'undefined' ? future_value : 0;

    let returnValue = 0;
    if(rate_per_period != 0.0){
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        returnValue = -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

    } else if(number_of_payments != 0.0){
        // No interest rate, but number of payments exists
        returnValue = -(future_value + present_value) / number_of_payments;
    }

    return (Math.round(returnValue * 100) / 100).toString().replaceAll('.',',');
}

export function getRateLoan(cenaNettoParam,okresParam,wplataTypeParam,wplataParam,marzaParam,prowizjaParam, prowizjaPartner){
    let marza = parseFloat(marzaParam.toString().replaceAll(',','.'));
    let prowizja = parseFloat(prowizjaParam.replaceAll(',','.')) + prowizjaPartner;
    let cenaNetto = parseFloat(cenaNettoParam.replaceAll(',','.'));
    let okres = parseInt(okresParam);
    let wplata;
    let type = 0;
    if(wplataTypeParam === '%'){
        wplata = cenaNetto*(parseFloat(wplataParam.replaceAll(',','.'))/100);
    }else {
        wplata = parseFloat(wplataParam.replaceAll(',','.'));
    }

    let rate_per_period = ((marza) / 100)/12;
    let number_of_payments = okres;
    let present_value = -((cenaNetto - wplata) + ((cenaNetto - wplata) / (1 - (prowizja/100)) - (cenaNetto - wplata)));

    let future_value = 0;


    future_value = typeof future_value !== 'undefined' ? future_value : 0;

    let returnValue = 0;
    if(rate_per_period != 0.0){
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        returnValue = -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

    } else if(number_of_payments != 0.0){
        // No interest rate, but number of payments exists
        returnValue = -(future_value + present_value) / number_of_payments;
    }

    return (Math.round(returnValue * 100) / 100).toString().replaceAll('.',',');
}

export function tabelaProwizjiPozyczki(type,state,year,insurance) {
    switch (type) {
        case ('Pojazdy do 3,5 t'):
           switch (state) {
               case ('Nowy'):
                   switch (insurance) {
                       case ("Leasing"):
                           switch (year) {
                               case ("2024") : return "5,50";
                               case ("2023") : return "5,50";
                               case ("2022") : return "5,50";
                               case ("2021") : return "5,50";
                               case ("2020") : return "5,50";
                               case ("2019") : return "5,50";
                               default: return "5,49";
                           }
                       case ("Obce"):
                           switch (year) {
                               case ("2024") : return "6,00";
                               case ("2023") : return "6,00";
                               case ("2022") : return "6,00";
                               case ("2021") : return "6,00";
                               case ("2020") : return "6,00";
                               case ("2019") : return "6,00";
                               default: return "6,00";
                           }
                   }
               case ('Używany'):
                   switch (insurance) {
                       case ("Leasing"):
                           switch (year) {
                               case ("2024") : return "6,50";
                               case ("2023") : return "6,50";
                               case ("2022") : return "6,50";
                               case ("2021") : return "6,50";
                               case ("2020") : return "6,50";
                               case ("2019") : return "6,50";
                               case ("2018") : return "6,50";
                               case ("2017") : return "6,50";
                               case ("2016") : return "6,50";
                               case ("2015") : return "7,50";
                           }
                       case ("Obce"):
                           switch (year) {
                               case ("2024") : return "7,00";
                               case ("2023") : return "7,00";
                               case ("2022") : return "7,00";
                               case ("2021") : return "7,00";
                               case ("2020") : return "7,00";
                               case ("2019") : return "7,00";
                               case ("2018") : return "7,00";
                               case ("2017") : return "7,00";
                               case ("2016") : return "7,00";
                               case ("2015") : return "8,00";
                           }
                   }
               }
        case ('Pojazdy pow. 3,5 t'):
            switch (state) {
                case ('Nowy'):
                    switch (insurance) {
                        case ("Leasing"):
                            switch (year) {
                                case ("2024") : return "5,50";
                                case ("2023") : return "5,50";
                                case ("2022") : return "5,50";
                                case ("2021") : return "5,50";
                                case ("2020") : return "5,50";
                                case ("2019") : return "5,50";
                                default: return "5,50";
                            }
                        case ("Obce"):
                            switch (year) {
                                case ("2024") : return "6,00";
                                case ("2023") : return "6,00";
                                case ("2022") : return "6,00";
                                case ("2021") : return "6,00";
                                case ("2020") : return "6,00";
                                case ("2019") : return "6,00";
                                default: return "6,00";
                            }
                    }
                case ('Używany'):
                    switch (insurance) {
                        case ("Leasing"):
                            switch (year) {
                                case ("2024") : return "6,50";
                                case ("2023") : return "6,50";
                                case ("2022") : return "6,50";
                                case ("2021") : return "6,50";
                                case ("2020") : return "6,50";
                                case ("2019") : return "6,50";
                                case ("2018") : return "6,50";
                                case ("2017") : return "6,50";
                                case ("2016") : return "6,50";
                                default: return "7,50";
                            }
                        case ("Obce"):
                            switch (year) {
                                case ("2024") : return "7,00";
                                case ("2023") : return "7,00";
                                case ("2022") : return "7,00";
                                case ("2021") : return "7,00";
                                case ("2020") : return "7,00";
                                case ("2019") : return "7,00";
                                case ("2018") : return "7,00";
                                case ("2017") : return "7,00";
                                case ("2016") : return "7,00";
                                default: return "8,00";
                            }
                    }

            }
       }
};

export function tabelaProwizjiLeasingu(type,year,state = 'Używany') {
    switch (state) {
        case ('Nowy'):
            switch (type) {
                case ("Standard"):
                    return "3,5";
                case ("Standard+"):
                    return "3,0";
            }
        case ('Używany'):
            switch (type) {
                case ("Standard"):
                    switch (year) {
                        case ("2024") :
                            return "3,5";
                        case ("2023") :
                            return "3,5";
                        case ("2022") :
                            return "3,5";
                        case ("2021") :
                            return "4,0";
                        case ("2020") :
                            return "4,0";
                        case ("2019") :
                            return "4,0";
                        case ("2018") :
                            return "4,0";
                        default:
                            return "5,0";
                    }
                case ("Standard+"):
                    switch (year) {
                        case ("2023") :
                            return "3,0";
                        case ("2024") :
                            return "3,0";
                        case ("2024") :
                            return "3,0";
                        case ("2022") :
                            return "3,0";
                        case ("2021") :
                            return "3,5";
                        case ("2020") :
                            return "3,5";
                        case ("2019") :
                            return "3,5";
                        case ("2018") :
                            return "3,5";
                        default:
                            return "4,5";
                    }
            }
    }
};

export const getAllLeasingPrice = (state,object) => {
    let allCostPirice;
    let allCostPresentage;

    let price = parseFloat(state.price.replaceAll(',','.'));
    let okres = parseInt(object.period);
    let rata = parseInt(object.rate.replaceAll(',','.'));
    let wplata;
    let wykup;

    if(object.contribution.type === '%'){
        wplata = price*(parseFloat(object.contribution.value.replaceAll(',','.'))/100);
    }else {
        wplata = parseFloat(object.contribution.value.replaceAll(',','.'));
    }

    if(object.repurchase.type === '%'){
        wykup = price*(parseFloat(object.repurchase.value.replaceAll(',','.'))/100);
    }else {
        wykup = parseFloat(object.repurchase.value.replaceAll(',','.'));
    }

    allCostPirice = (Math.round((wplata+rata*okres+wykup) * 100) / 100);
    allCostPresentage = (Math.round((allCostPirice/price) * 10000) / 100);

    return [isNaN(allCostPirice) ? '~' : allCostPirice,isNaN(allCostPresentage) ? `~` : allCostPresentage]
};

export const getAllLoanPrice = (state,object) => {
    let allCostPirice;
    let allCostPresentage;

    let price = parseFloat(state.price.replaceAll(',','.'));
    let okres = parseInt(object.period);
    let rata = parseInt(object.rate.replaceAll(',','.'));
    let wplata;
    let wykup = 0;

    if(object.contribution.type === '%'){
        wplata = price*(parseFloat(object.contribution.value.replaceAll(',','.'))/100);
    }else {
        wplata = parseFloat(object.contribution.value.replaceAll(',','.'));
    }


    allCostPirice = (Math.round((wplata+rata*okres+wykup) * 100) / 100);
    allCostPresentage = Math.round((allCostPirice/price)*100);

    return [isNaN(allCostPirice) ? '~' : allCostPirice,isNaN(allCostPresentage) ? `~` : allCostPresentage]
};
