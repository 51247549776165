import {db, myFirebase} from "../../firebase/firebase";

export const LOAD_CONFIGURATION = "LOAD_CONFIGURATION";
export const SET_CONFIGURATION_PROPERTY = "SET_CONFIGURATION_PROPERTY";

const loadConfiguration = (data) => {
    return {
        type: LOAD_CONFIGURATION,
        payload:data
    };
};

export const setConfigurationProperty = (data) => {
    return {
        type: LOAD_CONFIGURATION,
        payload:data
    };
};

export const loadConfigurationWorker = () => dispatch => {
    db.collection("configuration")
        .doc("general")
        .get()
        .then((docRef) => {
            dispatch(loadConfiguration(docRef.data()))
        })
};
