import {db, myFirebase} from "../../firebase/firebase";

export const LOAD_WORKERS = "LOAD_WORKERS";

const loadWorkers = (workers) => {
    return {
        type: LOAD_WORKERS,
        payload:workers
    };
};

export const loadWorkersFunc = (workers) => dispatch => {
    dispatch(loadWorkers(workers))
};
