import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import Navigation from "./navigation/navigation";
import { connect } from "react-redux";
import Dashboard from "../../../components/dashboard";
import {Redirect, Route} from "react-router-dom";
import Students from "./content/otomoto/otomoto";

function Partner (props) {
    const { dispatch,user } = props;

    return(
        <Dashboard>
            <Navigation/>
            <div style={{flex:'1 1',background:'rgb(240,240,240)',padding:'30px'}}>
                <Route path = '/panel/otomoto' component={Students} />

                <Route render={() => <Redirect to="/panel/otomoto" />} />
            </div>
        </Dashboard>
    )
};

Partner.propTypes = {
};

function mapStateToProps(state) {
    return {
        isLoggingOut: state.auth.isLoggingOut,
        logoutError: state.auth.logoutError,
        user:state.auth.user
    };
}
export default connect(mapStateToProps)(Partner);